import './style.scss';

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Accordion } from 'react-bootstrap';
import { Page } from '../../components/Layout';
import { StoresDictionary as ST, RoutesDictionary as RT } from '../../utils/constants';
import { Spinner } from '../../components/Spinner';
import { ModalBox } from '../../components/Modal';
import { Modals } from '../../stores/UserStore';

export const LogOn = inject(ST.UserStore)(
  observer(({ UserStore }) => {
    const [userForm, setUserForm] = React.useState({ user: '', password: '' });

    const onInputChange = (key, value) => setUserForm({ ...userForm, [key]: value });

    const onSubitForm = () => UserStore.userAuthentication(userForm);

    return (
      <Page className="login-container align-center">
        <header className="margin-y-xl padding-y-lg">
          <h1 className="login-title no-margin padding-top-md">Automatic Matching Engine</h1>
        </header>
        <div className="container login-inner-container margin-bottom-lg padding-bottom-lg" id="Login">
          <div className="row pale-grey4-bg">
            <div className="col-md-12">
              <h2 className="dark-slate-blue light align-left padding-sm margin-y-xs">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="user-lock"
                  className="user-lock-icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="#22205f"
                    d="M320 320c0-11.1 3.1-21.4 8.1-30.5-4.8-.5-9.5-1.5-14.5-1.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h280.9c-5.5-9.5-8.9-20.3-8.9-32V320zm-96-64c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm384 32h-32v-48c0-44.2-35.8-80-80-80s-80 35.8-80 80v48h-32c-17.7 0-32 14.3-32 32v160c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32zm-80 0h-64v-48c0-17.6 14.4-32 32-32s32 14.4 32 32v48z"
                  />
                </svg>
                Login
              </h2>
            </div>
          </div>
          <div className="row white-bg padding-top-lg padding-bottom-xl">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 form-title">
                  <p className="padding-sm x-bigger align-center">Enter your credentials to access the service</p>
                </div>
              </div>
              <div className="row margin-top-sm">
                {UserStore.authenticationFailed && (
                  <div className="validation-summary-errors upcas no-margin-bottom">
                    <span>The login was not successful. Please correct the errors and try again.</span>
                    <ul className="no-padding">
                      <li>The Username or Password entered is incorrect</li>
                    </ul>
                  </div>
                )}
                <div className="col-md-6 offset-md-3">
                  <form
                    className="row"
                    onSubmit={e => {
                      e.preventDefault();
                      onSubitForm();
                    }}
                  >
                    <div className="col-md-12 user-input">
                      <input
                        id="UserName"
                        name="UserName"
                        placeholder="User name"
                        type="text"
                        value={userForm.user}
                        onChange={e => onInputChange('user', e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 password-input">
                      <input
                        id="Password"
                        name="Password"
                        placeholder="Password"
                        type="password"
                        value={userForm.password}
                        onChange={e => onInputChange('password', e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="button-big bigger corn-flower-blue-bg margin-top-md"
                        disabled={UserStore.loading.auth}
                      >
                        <Spinner inline size={1} loading={UserStore.loading.auth} className="margin-right-sm" />
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <Accordion>
                <div className="row margin-top-xl">
                  <div className="col-md-12">
                    <a href={`#${RT.RESETPASSWORD}`}>Reset Password</a>
                    <span className="vsep margin-x-xs x-bigger" />
                    <Accordion.Toggle as="a" eventKey="0">
                      Login problems?
                    </Accordion.Toggle>
                  </div>
                </div>
                <Accordion.Collapse eventKey="0">
                  <div className="border-solid pale-grey-bg no-margin padding-lg">
                    <div className="round-bottom-left round-bottom-right">
                      <span className="upcas label">Send us an email at </span>
                      <a href="mailto:customerserviceitaly@cribis.com">customerserviceitaly@cribis.com</a>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          </div>
        </div>
      </Page>
    );
  })
);

export const ResetPassword = inject(ST.UserStore)(
  observer(({ UserStore }) => {
    const [userForm, setUserForm] = React.useState({ user: '', email: '' });

    const onInputChange = (key, value) => setUserForm({ ...userForm, [key]: value });

    const onSubitForm = () => UserStore.resetPassword(userForm);

    return (
      <Page className="login-container align-center">
        <header className="margin-y-xl padding-y-lg">
          <h1 className="login-title no-margin padding-top-md">Automatic Matching Engine</h1>
        </header>
        <div className="container login-inner-container margin-bottom-lg padding-bottom-lg" id="Login">
          <div className="row pale-grey4-bg">
            <div className="col-md-12">
              <h2 className="dark-slate-blue light align-left padding-sm margin-y-xs">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="user-lock"
                  className="user-lock-icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="#22205f"
                    d="M320 320c0-11.1 3.1-21.4 8.1-30.5-4.8-.5-9.5-1.5-14.5-1.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h280.9c-5.5-9.5-8.9-20.3-8.9-32V320zm-96-64c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm384 32h-32v-48c0-44.2-35.8-80-80-80s-80 35.8-80 80v48h-32c-17.7 0-32 14.3-32 32v160c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32zm-80 0h-64v-48c0-17.6 14.4-32 32-32s32 14.4 32 32v48z"
                  />
                </svg>
                Reset Password
              </h2>
            </div>
          </div>
          <div className="row white-bg padding-top-lg padding-bottom-xl">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 form-title">
                  <p className="padding-sm x-bigger align-center">
                    Enter the Username and the Email address to which your account is associated. You will receive an
                    email with the user credential.
                  </p>
                </div>
              </div>
              <div className="row margin-top-sm">
                {UserStore.resetPasswordFailed && (
                  <>
                    <div className="validation-summary-errors upcas no-margin-bottom">
                      <span>The password has not been reset</span>
                      <ul className="no-padding">
                        <li>The Username or Email entered is incorrect</li>
                      </ul>
                    </div>
                    <div className="col-md-12">
                      <p className="upcas align-center label-medium margin-top-sm">
                        Please try again, or contact our customer service
                        <a className="block" href="mailto:customerserviceitaly@cribis.com">
                          customerserviceitaly@cribis.com
                        </a>
                      </p>
                    </div>
                  </>
                )}
                <div className="col-md-6 offset-md-3">
                  <form
                    className="row"
                    onSubmit={e => {
                      e.preventDefault();
                      onSubitForm();
                    }}
                  >
                    <div className="col-md-12 user-input">
                      <input
                        id="UserName"
                        name="UserName"
                        placeholder="User name"
                        type="text"
                        value={userForm.user}
                        onChange={e => onInputChange('user', e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 email-input">
                      <input
                        id="Email"
                        name="Email"
                        placeholder="E-mail"
                        type="text"
                        value={userForm.email}
                        onChange={e => onInputChange('email', e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="button-big bigger corn-flower-blue-bg margin-top-md"
                        disabled={UserStore.loading.reset}
                      >
                        <Spinner inline size={1} loading={UserStore.loading.reset} className="margin-right-sm" />
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row margin-top-xl">
                <div className="col-md-12">
                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault();
                      UserStore.goToLogin();
                    }}
                  >
                    Go back to the Login page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBox open={UserStore.modal === Modals.resetSuccess} title="Reset Password">
          <p className="alert-success padding-md margin-bottom-md">
            Your password has been successfully reset. You will receive an email with a temporary password.
          </p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="corn-flower-blue-bg white" onClick={UserStore.goToLogin}>
              Back to Login
            </button>
          </div>
        </ModalBox>
      </Page>
    );
  })
);

export const ChangePassword = inject(ST.UserStore)(
  observer(({ UserStore }) => {
    const [userForm, setUserForm] = React.useState({ newPassword: '', confirmPassword: '' });

    const onInputChange = (key, value) => setUserForm({ ...userForm, [key]: value });

    const onSubitForm = () => UserStore.changePassword(userForm);

    return (
      <Page className="login-container align-center">
        <header className="margin-y-xl padding-y-lg">
          <h1 className="login-title no-margin padding-top-md">Automatic Matching Engine</h1>
        </header>
        <div className="container login-inner-container margin-bottom-lg padding-bottom-lg" id="Login">
          <div className="row pale-grey4-bg">
            <div className="col-md-12">
              <h2 className="dark-slate-blue light align-left padding-sm margin-y-xs">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="user-lock"
                  className="user-lock-icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="#22205f"
                    d="M320 320c0-11.1 3.1-21.4 8.1-30.5-4.8-.5-9.5-1.5-14.5-1.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h280.9c-5.5-9.5-8.9-20.3-8.9-32V320zm-96-64c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm384 32h-32v-48c0-44.2-35.8-80-80-80s-80 35.8-80 80v48h-32c-17.7 0-32 14.3-32 32v160c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32zm-80 0h-64v-48c0-17.6 14.4-32 32-32s32 14.4 32 32v48z"
                  />
                </svg>
                Change Password
              </h2>
            </div>
          </div>
          <div className="row white-bg padding-top-lg padding-bottom-xl">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 form-title">
                  <p className="padding-sm x-bigger align-center no-margin">Enter the new password for your account.</p>
                </div>
              </div>
              <div className="row margin-top-sm">
                {UserStore.passwordFailed && (
                  <div className="validation-summary-errors upcas no-margin-bottom">
                    <span>The passwords entered are different or not valid. Please correct and try again.</span>
                  </div>
                )}
                <div className="col-md-6 offset-md-3">
                  <form
                    className="row"
                    onSubmit={e => {
                      e.preventDefault();
                      onSubitForm();
                    }}
                  >
                    <div className="col-md-12 password-input">
                      <input
                        id="NewPassword"
                        name="NewPassword"
                        placeholder="New Password"
                        type="password"
                        value={userForm.newPassword}
                        onChange={e => onInputChange('newPassword', e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 password-input">
                      <input
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        value={userForm.confirmPassword}
                        onChange={e => onInputChange('confirmPassword', e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="button-big bigger corn-flower-blue-bg margin-top-md"
                        disabled={UserStore.loading.change}
                      >
                        <Spinner inline size={1} loading={UserStore.loading.change} className="margin-right-sm" />
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  })
);
