import React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import { Row, Col, Form } from 'react-bootstrap';
import { IFormField } from '../../components/Form';

export const FormBox = ({ title, fields = [], data = {}, submitLabel, onSubmit }) => {
  const initForm = _.reduce(fields, (res, { key }) => ({ ...res, [key]: data[key] || '' }), {});
  const [form, setForm] = React.useState(initForm);

  const onSubmitForm = () => {
    onSubmit(form);
  };

  const onChange = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  return (
    <>
      {title && <h3 className="form-title capit">{title}</h3>}
      <div className="greybox-dark padding-lg margin-bottom-lg">
        <Form
          onSubmit={e => {
            e.preventDefault();
            onSubmitForm();
          }}
        >
          {_.map(fields, (field, idx) => (
            <Form.Group key={idx} as={Row} className="no-margin-bottom" controlId={field.key}>
              <Col md={4} sm={12} className="no-padding-right">
                <Form.Label>{field.label}</Form.Label>
              </Col>
              <Col md={8} sm={12} className={field.type === 'password' ? 'password-input' : ''}>
                <Form.Control
                  type={field.type}
                  value={form[field.key]}
                  onChange={e => onChange(field.key, e.target.value)}
                />
              </Col>
            </Form.Group>
          ))}
          <Row>
            <Col md={{ span: 8, offset: 4 }} sm={12} className="margin-top-md">
              <button type="submit" className="button-big small std-label upcas corn-flower-blue-bg">
                {submitLabel}
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

FormBox.propTypes = {
  title: PT.string,
  fields: PT.arrayOf(PT.shape(IFormField)),
  data: PT.shape({}),
  submitLabel: PT.string.isRequired,
  onSubmit: PT.func.isRequired
};
