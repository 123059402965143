import './style.scss';

import React from 'react';
import PT from 'prop-types';
import { Pagination } from 'react-bootstrap';
import _ from 'lodash';

const BASE_SHIFT = 0;
const PAGE_SHIFT = 1;
const Type = {
  first: 'firstPage',
  prev: 'prevPage',
  prevSet: 'prevSet',
  nextSet: 'nextSet',
  next: 'nextPage',
  last: 'lastPage'
};

export const Pager = ({ current, total, limit = 5, onChange }) => {
  const pagerSections = {
    total: Math.ceil(total / limit),
    current: Math.ceil((current + PAGE_SHIFT) / limit) - PAGE_SHIFT,
    size: limit
  };

  const activeSection = () => {
    const start = pagerSections.current * pagerSections.size;
    const delta = total - start;
    const end = start + (delta > pagerSections.size ? pagerSections.size : delta);
    return _.range(start + PAGE_SHIFT, end + PAGE_SHIFT);
  };

  const disableEllipsis = {
    prev: pagerSections.total === PAGE_SHIFT || pagerSections.current === BASE_SHIFT,
    next:
      pagerSections.total === PAGE_SHIFT ||
      pagerSections.current === pagerSections.total - PAGE_SHIFT ||
      pagerSections.total === 0
  };

  const onChangePage = e => {
    const page = e.target.name || e.target.parentNode.name;
    if (!page) return null;
    switch (page) {
      case Type.first:
        return onChange(BASE_SHIFT);
      case Type.prev:
        return onChange(current - PAGE_SHIFT);
      case Type.prevSet:
        return onChange(pagerSections.current * pagerSections.size - PAGE_SHIFT);
      case Type.nextSet:
        return onChange((pagerSections.current + PAGE_SHIFT) * pagerSections.size);
      case Type.next:
        return onChange(current + PAGE_SHIFT);
      case Type.last:
        return onChange(total - PAGE_SHIFT);
      default:
        return onChange(Number(page));
    }
  };

  return (
    <Pagination onClick={onChangePage}>
      <Pagination.First disabled={current <= BASE_SHIFT} name={Type.first} />
      <Pagination.Prev disabled={current <= BASE_SHIFT} name={Type.prev} />
      {!disableEllipsis.prev && <Pagination.Ellipsis name={Type.prevSet} />}

      {_.map(activeSection(), (page, idx) => (
        <Pagination.Item key={idx} name={page - PAGE_SHIFT} active={current === page - PAGE_SHIFT}>
          {page}
        </Pagination.Item>
      ))}

      {!disableEllipsis.next && <Pagination.Ellipsis name={Type.nextSet} />}
      <Pagination.Next disabled={current >= total - PAGE_SHIFT} name={Type.next} />
      <Pagination.Last disabled={current >= total - PAGE_SHIFT} name={Type.last} />
    </Pagination>
  );
};

Pager.propTypes = {
  current: PT.number.isRequired,
  total: PT.number.isRequired,
  limit: PT.number,
  onChange: PT.func.isRequired
};
