import PT from 'prop-types';

export const IFilter = {
  type: PT.oneOf(['text', 'datetime', 'daterange', 'drilldown']),
  width: PT.number,
  key: PT.string,
  label: PT.string
};

export const IDrilldown = {
  options: PT.shape({})
};
