module.exports = {
  GetSettings: {
    url: '/Cacib/GetSettings',
    type: 'GET',
    fake: false
  },
  UpdateUserInfo: {
    url: '/Account/UpdateUserInfo',
    type: 'POST',
    fake: false
  },
  SaveSettings: {
    url: '/Cacib/SaveSettings',
    type: 'POST',
    fake: false
  }
};
