// eslint-disable-next-line import/no-default-export
export default {
  DownloadArea: 'Download Area',
  RequestsUpload: 'Requests Upload',
  ManualBrowsing: 'Manual Browsing',
  AutomaticMatch: 'Automatic Match',
  AutomaticCA: 'Automatic Credit Assessment',
  CreditAssessment: 'Credit Assessment',
  UserManual: 'User Manual',
  DownloadTemplate: 'Download Template',
  Match: 'Match',
  Fields: {
    FileName: 'Input File Name',
    Status: 'Status',
    Date: 'Request Date',
    Matched: 'Matched Business',
    NotMatched: 'Not Matched Business'
  },
  Total: 'total requests',
  EmptyLabel: 'no data waiting for manual browsing',
  UploadSuccess: 'Upload Successful',
  UploadError: 'Upload Error',
  SuccessMessage: 'Monitor the status in MyDocs'
};
