import './style.scss';

import React from 'react';
import PT from 'prop-types';
import { Modal } from 'react-bootstrap';

export const ModalBox = ({ className, open, size, title, closeButton, onClose, withError, children }) => (
  <Modal
    centered="true"
    className={`modal-marker ${className || ''} ${withError ? 'modal-error' : ''}`}
    size={size || 'lg'}
    show={open}
    onHide={onClose}
    aria-labelledby="contained-modal-title-vcenter"
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header className={withError ? 'watermelon-bg' : ''}>
      {title && (
        <>
          <h2 className="title-mid no-margin align-center">{title}</h2>
          {closeButton && (
            <button type="button" className="close" onClick={onClose}>
              <i className="icon-close-big bigger" />
            </button>
          )}
        </>
      )}
    </Modal.Header>
    <Modal.Body className="align-center">
      {children}
      {withError && (
        <>
          <p className="steel big no-margin">
            Please try again or contact our customer service
            <a className="block corn-flower-blue" href="mailto:customerserviceitaly@cribis.com">
              customerserviceitaly@cribis.com
            </a>
          </p>
          <div className="modal-buttons margin-top-sm">
            <button type="button" className="upcas steel-bg white" onClick={onClose}>
              Ok
            </button>
          </div>
        </>
      )}
    </Modal.Body>
  </Modal>
);
ModalBox.propTypes = {
  className: PT.string,
  open: PT.bool,
  size: PT.oneOf(['sm', 'lg', 'xl']),
  title: PT.string,
  closeButton: PT.bool,
  onClose: PT.func,
  withError: PT.bool,
  children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)])
};
