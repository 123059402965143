import { RoutesDictionary as RT, ViewsDictionary as VW, StorageDictionary as STG } from '../utils/constants';
import { Storage } from '../utils/storage';

export const routesMap = (load, guard) => ({
  [`/${RT.LOGIN}/?((w|.)*)/?([^/]*)`]: () => load(VW.LOGIN),
  [`/${RT.RESETPASSWORD}`]: () => load(VW.RESETPASSWORD),
  [`/${RT.CHANGEPASSWORD}`]: () => load(VW.CHANGEPASSWORD),
  [`/${RT.MYPROFILE}`]: next => {
    guard(() => load(VW.MYPROFILE));
    next(false);
  },
  [`/${RT.HOME}`]: next => {
    guard(() => load(VW.HOME));
    next(false);
  },
  [`/${RT.MYDOCS}`]: next => {
    guard(() => load(VW.MYDOCS));
    next(false);
  },
  [`/${RT.FILEMATCHER(':id')}`]: (id, next) => {
    guard(() => load(VW.FILEMATCHER, { id }));
    next(false);
  },
  [`/${RT.STATEMENT}`]: async next => {
    const userInfo = await Storage.read(STG.UserInfo);
    guard(() => load(VW.STATEMENT), userInfo && !!userInfo.hasCacibUsageRole);
    next(false);
  }
});
