import React from 'react';
import PT from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import _ from 'lodash';
import { Spinner } from '../../components/Spinner';

export const Settings = ({ confidenceCode, loading, onChange, onUpdate }) => (
  <>
    <Row>
      <Col sm={12}>
        <h4 className="form-title margin-bottom-sm margin-top-lg">Request Settings</h4>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <div className="greybox padding-md margin-bottom-lg">
          <Form
            onSubmit={e => {
              e.preventDefault();
              onUpdate();
            }}
          >
            <Form.Group as={Row} controlId="xcodx">
              <Col md={6} sm={12} className="no-padding-right">
                <Form.Label>Confidence code</Form.Label>
                <p className="link-silver-small text-right">(set the minimum confidence code for matching)</p>
              </Col>
              <Col md={6} sm={12}>
                <Form.Control as="select" value={confidenceCode} onChange={e => onChange(_.toNumber(e.target.value))}>
                  {_.map(_.range(1, 11), (num, idx) => (
                    <option key={idx} value={num}>
                      {num}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Row>
              <Col sm={12} className="align-center margin-top-sm">
                <button
                  type="submit"
                  className="button-big small std-label upcas corn-flower-blue-bg"
                  disabled={loading}
                >
                  <Spinner inline size={1} loading={loading} className="margin-right-xs" />
                  save
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  </>
);

Settings.propTypes = {
  confidenceCode: PT.number,
  loading: PT.bool,
  onChange: PT.func,
  onUpdate: PT.func
};
