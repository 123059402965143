import React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import { StyledTable as Table, ITableField } from '../../components/Table';

export const ManualBrowsingList = ({ t, loading, fields, data = [], empty, onSelect }) => (
  <Table>
    <Table.Header fields={fields} t={key => t(`Fields.${key}`)}>
      <Table.HeaderCell label={t('Match')} width="140" />
    </Table.Header>
    <Table.Body>
      {data.length > 0 || loading ? (
        _.map(data, (item, idx) => (
          <Table.Row key={idx} fields={fields} value={key => item[key]} highlight={key => key === 'fileName'}>
            <Table.Cell custom>
              <button
                type="button"
                className="button-big std-label upcas corn-flower-blue-bg no-min-width margin-y-sm"
                onClick={() => onSelect(item.id)}
              >
                {t('Match')}
              </button>
            </Table.Cell>
          </Table.Row>
        ))
      ) : (
        <Table.EmptyRow colSpan={fields.length + 1} label={empty} />
      )}
    </Table.Body>
  </Table>
);

ManualBrowsingList.propTypes = {
  t: PT.func,
  loading: PT.bool,
  fields: PT.arrayOf(PT.shape(ITableField)).isRequired,
  data: PT.arrayOf(PT.shape({})),
  empty: PT.string,
  onSelect: PT.func
};
