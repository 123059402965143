import _ from 'lodash';
import moment from 'moment';
import Api from './api';
import { callApi } from '../fetch';

const Sites = {
  S: 'Single',
  H: 'Headquarters',
  B: 'Branch'
};

export const GetMatchDetails = async inputData => {
  const response = await callApi(Api.GetMailBoxDetails, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful,
    subjects: _.map(response.detailsList, subject => ({
      uid: subject.guid,
      id: subject.id,
      status: subject.state,
      debtorCode: subject.debtorCode,
      name: subject.name,
      address: subject.address,
      city: subject.city,
      postCode: subject.postCode,
      countryCode: subject.countryCode,
      sic: subject.sic,
      hqName: subject.hqName,
      hqCountry: subject.hqCountry,
      hqAddress: subject.hqAddress,
      hqTown: subject.hqTown,
      guName: subject.guName,
      guCountry: subject.guCountry,
      guAddress: subject.guAddress,
      guTown: subject.guTown,
      duns: subject.duns,
      dunsNbr: subject.dunsNbr,
      guRatingNeeded: subject.guRatingNeeded,
      county: subject.county,
      businessId: subject.businessId,
      matchList: _.map(subject.candidateList, candidate => ({
        name: candidate.companyName,
        address: candidate.address,
        town: candidate.town,
        cap: candidate.cap,
        legalStatus: Sites[candidate.legalStatus] || '',
        confidenceCode: candidate.confidenceCode,
        dunsNbr: candidate.dunsNbr,
        ctryCd: candidate.ctryCd,
        mtchGrd: candidate.mtchGrd,
        nmePctGrd: candidate.nmePctGrd,
        businessId: candidate.businessId,
        brInd: candidate.brInd,
      }))
    })),
    pagingInfo: response.pagingInfo
  };
};

export const GetMatchInfo = async inputData => {
  const response = await callApi(Api.GetMailBox, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful,
    subject: {
      id: response.guid,
      fileName: response.filename,
      status: response.state,
      insertDate: moment(response.insertDate).format('L'),
      matchAmount: response.numMatched,
      noMatchAmount: response.numNotMatched
    }
  };
};

export const MatchSubject = async inputData => {
  const response = await callApi(Api.UpdateMailBoxDetails, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful
  };
};

export const SkipSubject = async inputData => {
  const response = await callApi(Api.UpdateMailBoxDetailsSkip, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful
  };
};

export const StopMatch = async inputData => {
  const response = await callApi(Api.UpdateRemainingMailboxDetails, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful
  };
};

export const GetFamilyTree = async request => {
  const response = await callApi(Api.GetFamilyTree, {
    body: request.duns
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ...response,
    duns: request.duns
  };
};
