import './style.scss';

import React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import { Table } from 'react-bootstrap';

export const ITableField = {
  label: PT.string.isRequired,
  key: PT.string.isRequired,
  width: PT.string,
  align: PT.string,
  colSpan: PT.string
};

export const TableHeader = ({ t, fields, children }) => (
  <thead>
    <tr className="header-row">
      {_.map(fields, (field, idx) => (
        <TableHeaderCell
          key={idx}
          label={t(field.label)}
          colSpan={field.colSpan}
          width={field.width}
          align={field.align}
        />
      ))}
      {children}
    </tr>
  </thead>
);
TableHeader.propTypes = {
  t: PT.func.isRequired,
  fields: PT.arrayOf(PT.shape(ITableField)).isRequired,
  children: PT.node
};

export const TableHeaderCell = ({ label, colSpan, width, align }) => (
  <th scope="col" colSpan={colSpan} width={width} className={`upcas std-label align-${align || 'center'}`}>
    {label}
  </th>
);
TableHeaderCell.propTypes = {
  label: PT.string.isRequired,
  width: PT.string,
  align: PT.string,
  colSpan: PT.string
};

export const EmptyRow = ({ colSpan, label }) => (
  <tr className="cll-row empty">
    <td colSpan={colSpan}>
      <p className="steel1 italic align-center no-margin">{label || 'no data available'}</p>
    </td>
  </tr>
);
EmptyRow.propTypes = {
  colSpan: PT.number,
  label: PT.string
};

export const TableRow = ({ fields, value, highlight, hasErrors, children }) => (
  <tr className={`cll-row ${hasErrors ? 'error' : ''}`}>
    {_.map(fields, (field, idx) => (
      <TableCell
        key={idx}
        width={field.width}
        align={field.align}
        value={value(field.key)}
        highlight={highlight && highlight(field.key)}
      />
    ))}
    {children}
  </tr>
);
TableRow.propTypes = {
  fields: PT.arrayOf(PT.shape(ITableField)).isRequired,
  value: PT.func.isRequired,
  highlight: PT.func,
  hasErrors: PT.bool,
  children: PT.node
};

export const TableCell = ({ width, align, highlight, value, custom, children }) => (
  <td width={width}>
    <div className={`align-${align || 'center'}`}>
      {!custom ? <span className={`steel ${highlight ? 'highlight' : ''}`}>{value}</span> : children}
    </div>
  </td>
);
TableCell.propTypes = {
  value: PT.oneOfType([PT.string, PT.number, PT.node]),
  width: PT.string,
  align: PT.string,
  highlight: PT.bool,
  custom: PT.bool,
  children: PT.node
};

export class StyledTable extends React.PureComponent {
  static Header = TableHeader;

  static HeaderCell = TableHeaderCell;

  static Body = ({ children }) => <tbody>{children}</tbody>;

  static EmptyRow = EmptyRow;

  static Row = TableRow;

  static Cell = TableCell;

  static Footer = ({ children }) => <tfoot>{children}</tfoot>;

  render() {
    return <Table {...this.props} />;
  }
}
