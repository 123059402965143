import _ from 'lodash';
import config from '../config';
import { RoutesDictionary as RT, StorageDictionary as STG } from '../utils/constants';
import { Storage } from '../utils/storage';
import { E } from '../utils/errors';

export class ApiHelper {
  static generateHeaders = (method, formData) => {
    const headers = new Headers();
    headers.append('Pragma', 'no-cache');
    headers.append('Cache-Control', 'no-cache');
    if (method === 'POST' && !formData) headers.append('Content-Type', 'application/json; charset=utf-8');

    return headers;
  };

  static getServerUrl = (fakeApi, fromProxy) => {
    if (fakeApi) return config.MOCK_SERVER;
    if (fromProxy) return `${config.REACT_APP_ROOT}/${config.REACT_APP_APIURL}`;
    return config.REACT_APP_ROOT;
  };

  static userLogout = async backUrl => {
    await Storage.store(STG.UserInfo, undefined);
    window.location.href = `#${RT.LOGIN}${backUrl && `?BackUrl=${encodeURIComponent(backUrl)}`}`;
  };

  static sessionExpired = () => {
    // eslint-disable-next-line no-console
    console.warn('session expired.');
    ApiHelper.userLogout(window.location.hash);
    throw new Error(E.SESSIONEXPIRED);
  };

  static checkStatus = (url, status, method) => {
    if (status === 403) ApiHelper.sessionExpired();
    if (status >= 500) throw new Error(`API Server Error (${status})\n${method} - ${_.replace(url, '/Proxy', '')}`);
    if (status >= 400) throw new Error(`API Client Error (${status})\n${method} - ${_.replace(url, '/Proxy', '')}`);

    return true;
  };

  static parseResponse = async res => {
    try {
      const result = await res.clone().json();
      return result;
    } catch (e) {
      return null;
    }
  };
}
