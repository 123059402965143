import './style.scss';

import React from 'react';
import PT from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

export const StyledSelect = ({ className, name, items, placeholder, noOptionsMessage, onChange }) => {
  const filterOptions = (el, searchText) => {
    if (!searchText) return el;
    return _.includes(_.toLower(el.data.text), _.toLower(searchText));
  };

  return (
    <div className={`styled-select-container ${className || ''}`}>
      <Select
        isMulti
        onChange={selected => onChange(name, selected)}
        placeholder={placeholder || ''}
        noOptionsMessage={() => noOptionsMessage}
        classNamePrefix="StyledSelect"
        className="StyledSelect"
        closeMenuOnSelect={false}
        value={_.filter(items || [], 'isSelected')}
        filterOption={filterOptions}
        getOptionValue={option => option.code}
        getOptionLabel={option => (
          <div className="filter-label label-small upcas filter-option">
            {option.text}
            <span className="filter-total margin-left-sm">{option.count || 0}</span>
          </div>
        )}
        options={items}
      />
    </div>
  );
};
StyledSelect.propTypes = {
  className: PT.string,
  name: PT.string,
  items: PT.arrayOf(PT.shape({})).isRequired,
  placeholder: PT.string,
  noOptionsMessage: PT.string,
  onChange: PT.func
};

export const ShortSelect = ({ item, name, onChange }) => {
  const onSelect = () => {
    onChange(name, item.code, !item.isSelected);
  };

  if (!item) return null;
  return (
    <span
      role="presentation"
      className={`filter-label label-small upcas ${item.isSelected && 'selected'}`}
      onClick={onSelect}
    >
      {item.text}
      <span className="filter-total">{item.count || 0}</span>
    </span>
  );
};
ShortSelect.propTypes = {
  name: PT.string,
  item: PT.shape({
    text: PT.string,
    count: PT.number,
    code: PT.string,
    isSelected: PT.bool
  }),
  onChange: PT.func
};
