import localForage from 'localforage';
import { stringify, parse } from 'flatted';

// export const store = async (key, data) =>
//   new Promise((resolve, reject) => {
//     localForage
//       .setItem(key, stringify(data))
//       .then(() => resolve(key))
//       .catch(error => reject(error));
//   });

// export const read = async key =>
//   new Promise((resolve, reject) => {
//     localForage
//       .getItem(key)
//       .then(value => resolve(value ? parse(value) : null))
//       .catch(error => reject(error));
//   });

export class Storage {
  static store = async (key, data) =>
    new Promise((resolve, reject) => {
      localForage
        .setItem(key, stringify(data))
        .then(() => resolve(key))
        .catch(error => reject(error));
    });

  static read = async key =>
    new Promise((resolve, reject) => {
      localForage
        .getItem(key)
        .then(value => resolve(value ? parse(value) : null))
        .catch(error => reject(error));
    });
}
