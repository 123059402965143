// eslint-disable-next-line import/no-default-export
export default {
  PageTitle: 'File Matcher',
  SubjectsList: 'Subjects to be matched',
  Total: 'total subjects',
  MatcherModal: 'Match Subject',
  Close: 'Close',
  EndBrowsing: 'End Browsing',
  EndBrowsingConfirm: 'Are you sure you want to end matching?',
  EndBrowsingAlert: 'All not matched subjects will be skipped automatically.',
  FileInput: 'Input file',
  RequestFields: {
    Filename: 'Name',
    Status: 'Status',
    InsertDate: 'Request date',
    Matched: 'Matched business',
    NotMatched: 'Not matched business'
  },
  SubjectFields: {
    Id: 'Id',
    Name: 'Name',
    Address: 'Address',
    Town: 'Town',
    ZipCode: 'Zip code',
    DebtorCode: 'Debtor code',
    BusinessId: 'Business id',
    CountryCode: 'Country code',
    Country: 'County',
    Duns: 'Duns',
    GURating: 'GU rating needed'
  },
  CandidateFields: {
    ConfidenceCode: 'Conf. code',
    Address: 'Address',
    Town: 'Town',
    ZipCode: 'Zip code',
    Site: 'Site',
    LegalStatus: 'Status',
    Country: 'Country',
    Sic: 'Sic'
  },
  NoCandidates: 'no match found',
  Select: 'Select',
  CloseTooltip: 'continue later',
  InputSubject: 'Input subject',
  SkipMatch: 'Skip match',
  SuggestedSubjects: 'Suggested subjects'
};
