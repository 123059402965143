import './common/style.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';

import { StoresDictionary as ST } from '../../utils/constants';
import { Page, SectionContainer } from '../../components/Layout';
import { Pagination } from '../../components/Pager';
import { BusyElement } from '../../components/Spinner';
import { ModalBox } from '../../components/Modal';
import { RequestsList } from './RequestsList';
import { SearchFilters } from './SearchFilters';

const RequestFields = [
  { label: 'Type', key: 'dunsType' },
  { label: 'Duns', key: 'duns' },
  { label: 'Country', key: 'country' },
  { label: 'RequestDate', key: 'requestDate' },
  { label: 'Price', key: 'price' }
];

export const Statement = inject(ST.StatementStore)(
  observer(({ StatementStore: STStore }) => {
    const { t } = useTranslation(['statement', 'exceptions']);

    return (
      <Page boxed className="statement-marker" title={t('PageTitle')}>
        <SearchFilters
          defaultDateRange={STStore.defaultFilters.requestDate}
          downloadEnabled={STStore.downloadExcelEnabled}
          loading={STStore.loading}
          onSubmit={STStore.updateFilters}
          onReset={STStore.resetFilters}
          onDownload={STStore.downloadExcel}
          t={t}
        />
        <SectionContainer>
          <BusyElement block loading={STStore.loading.list}>
            <Row>
              <Col sm={12}>
                <RequestsList
                  fields={RequestFields}
                  data={STStore.requestsList.slice()}
                  loading={STStore.loading.list}
                  empty={t('EmptyLabel')}
                  t={t}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Pagination
                  className="margin-top-lg"
                  current={STStore.pagingInfo.pageIndex}
                  total={STStore.pagingInfo.pagesNumber}
                  pageSize={STStore.pagingInfo.pageSize}
                  onChangePage={STStore.changePage}
                  onChangeSize={STStore.changePageSize}
                >
                  <span className="big margin-right-md">
                    <span className="dark-slate-blue margin-right-sm">{STStore.pagingInfo.recordsTotalNumber}</span>
                    {t('Total')}
                  </span>
                </Pagination>
              </Col>
            </Row>
          </BusyElement>
        </SectionContainer>
        <ModalBox
          withError
          title={t('exceptions:Generic.Error')}
          open={STStore.error.download || STStore.error.list}
          onClose={STStore.closeError}
        >
          <p className="steel big margin-bottom-lg">{STStore.error.message || t('exceptions:Generic.Message')}</p>
        </ModalBox>
      </Page>
    );
  })
);
