import './style.scss';

import React from 'react';
import PT from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

export const PageTitle = ({ title, description }) => (
  <Row>
    {title && (
      <Col sm={12}>
        <h1 className="steel title-large light align-left margin-top-md">{title}</h1>
      </Col>
    )}
    {description && (
      <Col sm={12}>
        <p className="steel big light align-left margin-top-md no-margin-bottom">{description}</p>
      </Col>
    )}
  </Row>
);
PageTitle.propTypes = {
  title: PT.string,
  description: PT.string
};

export const Page = ({ className, boxed, title, description, children }) => (
  <Container className={`${boxed ? 'main-container' : ''} ${className || ''}`} fluid={!boxed}>
    <PageTitle title={title} description={description} />
    {children}
  </Container>
);
Page.propTypes = {
  className: PT.string,
  boxed: PT.bool,
  title: PT.string,
  description: PT.string,
  children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)])
};

export const StyledTitle = ({ className, sepClass, children }) => (
  <Row>
    <Col sm={12} className={`styledTitle ${className || ''}`}>
      {children}
      <span className={`title-sep ${sepClass || ''}`} />
    </Col>
  </Row>
);
StyledTitle.propTypes = {
  className: PT.string,
  sepClass: PT.string,
  children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)])
};

export const BoxTitle = ({ className, title, subtitle }) => (
  <StyledTitle className={`col-xs-12 ${className || ''}`}>
    <div className="title bigger dark-slate-blue">{title}</div>
    {subtitle && <p className="steel no-margin">{subtitle}</p>}
  </StyledTitle>
);
BoxTitle.propTypes = {
  className: PT.string,
  title: PT.string,
  subtitle: PT.string
};

export const SectionContainer = ({ className, children }) => (
  <div className={`shadowed-box padding-lg ${className || ''}`}>{children}</div>
);
SectionContainer.propTypes = {
  className: PT.string,
  children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)])
};
