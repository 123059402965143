import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { DateRange } from '../../components/Form';
import { Spinner } from '../../components/Spinner';

export const SearchFilters = ({ t, defaultDateRange, downloadEnabled, loading, onSubmit, onReset, onDownload }) => {
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [isValid, setValididy] = useState(false);

  useEffect(() => {
    if (!dateRange.start || !dateRange.end) setValididy(false);
    else setValididy(true);
  }, [dateRange]);

  const onSubmitFilters = e => {
    e.preventDefault();
    if (!dateRange.start || !dateRange.end) return;
    onSubmit({ requestDate: dateRange });
  };

  const onResetFilters = () => {
    setDateRange(defaultDateRange);
    onReset();
  };

  return (
    <div className="filter-box margin-top-xl">
      <Row>
        <Col xs={12} className="d-flex align-items-center">
          <Form className="d-flex align-items-end" onSubmit={onSubmitFilters}>
            <Form.Group controlId="date-range" className="no-margin">
              <Form.Label className="align-left no-margin padding-bottom-sm">
                <span className="dark-slate-blue">{t('Filters.DateRange')}</span>
              </Form.Label>
              <DateRange name="date-range" value={dateRange} onChange={setDateRange} />
            </Form.Group>
            <button
              type="submit"
              className="button-big std-label upcas corn-flower-blue-bg margin-left-sm"
              disabled={!isValid || loading.list}
            >
              <Spinner inline size={1} loading={loading.list} className="margin-right-xs" />
              {t('common:Search')}
            </button>
            <button
              type="button"
              className="button-big std-label upcas steel-bg margin-left-sm"
              onClick={onResetFilters}
              disabled={loading.list}
            >
              {t('common:Reset')}
            </button>
          </Form>
          {downloadEnabled && (
            <button
              type="button"
              className="button-big std-label upcas dark-slate-blue-bg ml-auto mt-2"
              onClick={onDownload}
              disabled={loading.download}
            >
              <Spinner inline size={1} loading={loading.download} className="margin-right-sm">
                <i className="icon-download big margin-right-sm" aria-hidden="true" />
              </Spinner>
              {t('DownloadExcel')}
            </button>
          )}
        </Col>
      </Row>
    </div>
  );
};

SearchFilters.propTypes = {
  t: PT.func.isRequired,
  defaultDateRange: PT.shape({
    start: PT.string,
    end: PT.string
  }),
  downloadEnabled: PT.bool,
  loading: PT.shape({
    list: PT.bool,
    download: PT.bool
  }),
  onSubmit: PT.func.isRequired,
  onReset: PT.func.isRequired,
  onDownload: PT.func
};
