import { observable, configure, action, decorate } from 'mobx';
import { fromPromise } from 'mobx-utils';
import _ from 'lodash';
import { GetSettings } from '../api/myprofile';
import { StoresDictionary, ViewStoreConnect as STConnet, StorageDictionary as STG } from '../utils/constants';
import { HomeStore } from './HomeStore';
import { SettingStore } from './SettingStore';
import { UserStore } from './UserStore';
import { MyDocsStore } from './MyDocsStore';
import { MatchStore } from './MatchStotre';
import { Storage } from '../utils/storage';
import { StatementStore } from './StatementStore';

configure({ enforceActions: 'observed', isolateGlobalState: true });

export class AppStore {
  currentView = null;

  activeViewStore = null;

  userProfile = null;

  loadingProfile = false;

  errorProfile = false;

  constructor() {
    this.UserStore = new UserStore(this.getUserProfile);
    this.SettingStore = new SettingStore();
    this.HomeStore = new HomeStore();
    this.MatchStore = new MatchStore();
    this.MyDocsStore = new MyDocsStore();
    this.StatementStore = new StatementStore();
  }

  getUserProfile = async () => {
    this.setLoadingProfile(true);
    this.errorProfile = false;
    try {
      const userProfile = await Storage.read(STG.UserInfo);
      if (userProfile) {
        const result = await GetSettings();
        this.updateUserProfile(
          !userProfile.userContacts && result
            ? {
                ...userProfile,
                userContacts: result.userInfo,
                settings: result.settings
              }
            : {
                ...userProfile,
                settings: result.settings
              }
        );
        await Storage.store(STG.UserInfo, this.userProfile);
      }
    } catch {
      this.errorProfile = true;
    }
    this.setLoadingProfile(false);
  };

  updateUserProfile = userProfile => {
    this.userProfile = userProfile;
  };

  setLoadingProfile = loading => {
    this.loadingProfile = loading;
  };

  authGuard = async () => {
    const userInfo = await Storage.read(STG.UserInfo);
    return userInfo && userInfo.authenticated;
  };

  showView = (viewName, params) => {
    const userProfile = { ...this.userProfile };
    const storeKey = STConnet[viewName];
    this.activeViewStore = this.stores[storeKey];
    this.currentView = {
      name: viewName,
      data:
        this.activeViewStore && this.activeViewStore.load
          ? fromPromise(this.activeViewStore.load({ userProfile, ...params }))
          : { state: 'fulfilled' },
      params
    };
  };

  logout = () => {
    window.location.href = 'Account/Logout';
  };

  mapStores = (stores, key) => (!this[key] ? stores : { ...stores, [key]: this[key] });

  get stores() {
    return _.reduce(StoresDictionary, this.mapStores, {});
  }
}

decorate(AppStore, {
  currentView: observable,
  activeViewStore: observable,
  loadingProfile: observable,
  updateUserProfile: action,
  setLoadingProfile: action,
  showView: action,
  logout: action
});
