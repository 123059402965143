import './style.scss';

import React from 'react';
import PT from 'prop-types';
import { Collapse, Form, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { StyledSelect } from '../Select';
import { IDrilldown, IFilter } from './types';
import { DateRange } from '../Form';

const FilterControl = ({ filter, value, onChange }) => {
  const onChangeDrilldown = (key, options) => {
    const selectedOptions = _.map(options, 'code');
    const updatedDrilldown = {
      options: _.map(value.options, op => ({
        ...op,
        isSelected: _.includes(selectedOptions, op.code)
      }))
    };
    onChange(key, updatedDrilldown);
  };

  const renderFilter = type => {
    switch (type) {
      case 'drilldown':
        return value ? (
          <StyledSelect
            name={filter.key}
            items={value.options.slice()}
            onChange={onChangeDrilldown}
            noOptionsMessage="no options available"
          />
        ) : null;
      case 'daterange':
        return <DateRange name={filter.key} value={value} onChange={range => onChange(filter.key, range)} />;
      default:
        return (
          <input
            type={filter.type}
            className="no-margin"
            value={value}
            onChange={e => onChange(filter.key, e.target.value)}
          />
        );
    }
  };

  return (
    <Form.Group as={Col} controlId={filter.key} lg={filter.width} md={6} sm={12}>
      <Form.Label className="align-left no-margin padding-bottom-sm">
        <span className="dark-slate-blue">{filter.label}</span>
      </Form.Label>
      {renderFilter(filter.type)}
    </Form.Group>
  );
};
FilterControl.propTypes = {
  filter: PT.shape(IFilter),
  value: PT.oneOfType([PT.string, PT.number, PT.shape(IDrilldown)]),
  onChange: PT.func
};

export const FiltersForm = ({ filters, drillDowns, toggleFilters, onApply, onReset }) => {
  const getInit = () => {
    const initFilters = _.reduce(
      filters,
      (res, { key, type }) =>
        type !== 'drilldown' ? { ...res, [key]: type === 'daterange' ? { start: '', end: '' } : '' } : { ...res },
      {}
    );
    const initDrilldowns = _.reduce(
      filters,
      (res, { key, type }) => (type === 'drilldown' ? { ...res, [key]: drillDowns[key] } : { ...res }),
      {}
    );
    return { initFilters, initDrilldowns };
  };

  const { initFilters, initDrilldowns } = getInit();
  const [activeFilters, setFilters] = React.useState(initFilters);
  const [activeDrilldowns, setDrilldowns] = React.useState(initDrilldowns);

  React.useEffect(() => {
    if (!_.isEqual(activeDrilldowns, initDrilldowns)) setDrilldowns(initDrilldowns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drillDowns]);

  const onFilterChange = (key, value) => {
    if (_.has(activeDrilldowns, key)) setDrilldowns({ ...activeDrilldowns, [key]: value });
    else setFilters({ ...activeFilters, [key]: value });
  };

  const applyFilters = () => {
    onApply({ ...activeFilters, drillDowns: { ...activeDrilldowns } });
    toggleFilters();
  };

  const resetFilters = () => {
    const reset = _.mapValues(activeFilters, _.constant(''));
    setFilters(reset);
    onReset({ ...reset, drillDowns: {} });
    toggleFilters();
  };

  return (
    <div className="search-params bluey-grey-bg">
      <Form>
        <Row>
          {_.map(filters, (filter, idx) => (
            <FilterControl
              key={idx}
              filter={filter}
              value={_.has(activeDrilldowns, filter.key) ? activeDrilldowns[filter.key] : activeFilters[filter.key]}
              onChange={onFilterChange}
            />
          ))}
        </Row>
        <div className="misc-filters filters-buttons padding-md text-center">
          <button type="button" className="reset-filters capit steel-bg" onClick={resetFilters}>
            remove all filters
          </button>
          <button type="button" className="apply-filters capit corn-flower-blue-bg" onClick={applyFilters}>
            apply filters
          </button>
        </div>
      </Form>
    </div>
  );
};
FiltersForm.propTypes = {
  filters: PT.arrayOf(PT.shape(IFilter)).isRequired,
  drillDowns: PT.objectOf(PT.shape(IDrilldown)),
  toggleFilters: PT.func,
  onApply: PT.func.isRequired,
  onReset: PT.func.isRequired
};

export const FiltersBox = ({ filters, drillDowns, onApply, onReset }) => {
  const [openSearch, setOpenSearch] = React.useState(false);

  const toggleFilters = () => setOpenSearch(!openSearch);

  return (
    <div className="filters-container filter-marker margin-bottom-xl margin-top-xl">
      <Collapse in={openSearch}>
        <div>
          <FiltersForm
            toggleFilters={toggleFilters}
            filters={filters}
            drillDowns={drillDowns}
            onApply={onApply}
            onReset={onReset}
          />
        </div>
      </Collapse>
      <div className={`filters-header ${!openSearch ? 'expanded' : 'collapsed'}`}>
        <Collapse in={!openSearch}>
          <div className="text-center">
            <div className="padding-md">
              <span className="filter-desc">Filter results by clicking the filters button</span>
            </div>
          </div>
        </Collapse>
        <div className="filter-button-container">
          <button type="button" onClick={toggleFilters}>
            <i className="fa fa-search white margin-right-sm" aria-hidden="true" />
            <span className="upcas dark-slate-blue">{openSearch ? 'close' : 'filters'}</span>
          </button>
        </div>
      </div>
    </div>
  );
};
FiltersBox.propTypes = {
  filters: PT.arrayOf(PT.shape(IFilter)).isRequired,
  drillDowns: PT.objectOf(PT.shape(IDrilldown)),
  onApply: PT.func,
  onReset: PT.func
};
