import './style.scss';

import React from 'react';
import PT from 'prop-types';

export const Spinner = ({ loading, size, inline, className, children }) => {
  const spinnerElement = () => (
    <span className={`${className || ''}`}>
      <i className={`fa fa-refresh fa-spin fa-${size || 3}x fa-fw`} />
      <span className="sr-only">Loading...</span>
    </span>
  );

  if (!loading) return <>{children}</>;

  return !inline ? <div className={`text-center ${className || ''}`}>{spinnerElement()}</div> : spinnerElement();
};
Spinner.propTypes = {
  loading: PT.bool,
  size: PT.number,
  inline: PT.bool,
  className: PT.string,
  children: PT.node
};

export const BusyElement = ({ loading, block, size, children }) => (
  <div className={`busy-container ${loading && block ? 'blockBox' : ''}`}>
    <Spinner className="busy-element" loading={loading} size={size || 4} />
    {children}
  </div>
);
BusyElement.propTypes = {
  loading: PT.bool,
  block: PT.bool,
  size: PT.number,
  children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)])
};
