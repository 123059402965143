import PT from 'prop-types';

export const IRequest = {
  id: PT.string,
  userName: PT.string,
  requestName: PT.string,
  fileName: PT.string,
  deliveryTime: PT.string,
  type: PT.number,
  typeLabel: PT.string,
  status: PT.string,
  insertDate: PT.string,
  matchAmount: PT.number,
  noMatchAmount: PT.number,
  processedAmount: PT.number,
  totalAmount: PT.number
};
