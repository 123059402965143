import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FileInput } from '../../components/Form';
import { Spinner } from '../../components/Spinner';
import { MatchTypes } from '../../stores/HomeStore';

export const UploadForm = ({ loading, onSubmit, settings }) => {
  const [type, setType] = React.useState(MatchTypes.ACA);
  const [file, setFile] = React.useState(null);
  const [exludeOutOfBusiness, setExludeOutOfBusiness] = React.useState(settings.exludeOutOfBusiness);
  const [excludeBranches, setExcludeBranches] = React.useState(settings.excludeBranches);
  const [confidenceCode, setConfidenceCode] = React.useState(settings.confidenceCode);
  const { t } = useTranslation(['home', 'common']);
  const handleExcludeBranches = () => {
    setExcludeBranches(!excludeBranches);
  };
  const handlEexludeOutOfBusiness = () => {
    setExludeOutOfBusiness(!exludeOutOfBusiness);
  };

  const onSubmitForm = async () => {
    const result = await onSubmit({
      file,
      uploadType: type,
      exludeOutOfBusiness,
      excludeBranches,
      confidenceCode
    });
    if (result) {
      setFile(null);
      setType(MatchTypes.ACA);
      setExludeOutOfBusiness(settings.exludeOutOfBusiness);
      setExcludeBranches(settings.excludeBranches);
      setConfidenceCode(settings.confidenceCode);
    }
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmitForm();
      }}
    >
      <Form.Check
        custom
        inline
        type="radio"
        name="uploadType"
        id="rd-upload-1"
        className="label-medium normal steel"
        label={t('AutomaticCA')}
        value={MatchTypes.ACA}
        checked={type === MatchTypes.ACA}
        onChange={e => setType(Number(e.target.value))}
      />
      <Form.Check
        custom
        inline
        type="radio"
        name="uploadType"
        id="rd-upload-2"
        className="label-medium normal steel"
        label={t('AutomaticMatch')}
        value={MatchTypes.AMT}
        checked={type === MatchTypes.AMT}
        onChange={e => setType(Number(e.target.value))}
      />
      <Form.Check
        custom
        inline
        type="radio"
        name="uploadType"
        id="rd-upload-3"
        className="label-medium normal steel"
        label={t('ManualBrowsing')}
        value={MatchTypes.MBR}
        checked={type === MatchTypes.MBR}
        onChange={e => setType(Number(e.target.value))}
      />
      <div className="upload-container margin-top-md">
        <FileInput name="file" file={file} onChange={setFile}>
          <button
            type="submit"
            className="button-big std-label upcas corn-flower-blue-bg margin-left-sm"
            disabled={!file || (!type && type !== 0) || loading}
          >
            <Spinner inline size={1} loading={loading} className="margin-right-xs" />
            {t('common:UploadFile')}
          </button>
        </FileInput>
      </div>
      <Form.Check
        custom
        inline
        type="checkbox"
        name="exludeOutOfBusiness"
        id="rd-upload-4"
        className="label-medium normal steel"
        label={t('common:exludeOutOfBusiness')}
        value={exludeOutOfBusiness}
        checked={exludeOutOfBusiness}
        onChange={handlEexludeOutOfBusiness}
      />
      <Form.Check
        custom
        inline
        type="checkbox"
        name="ExcludeBranches"
        id="rd-upload-5"
        className="label-medium normal steel no-margin"
        label={t('common:ExcludeBranches')}
        value={excludeBranches}
        checked={excludeBranches}
        onChange={handleExcludeBranches}
      />
      <input
        name="ConfidenceCode"
        type="text"
        id="rd-upload-6"
        className="label-medium normal steel margin-left-sm"
        value={confidenceCode}
        onChange={e => setConfidenceCode(e.target.value)}
        style={{ width: '42px' }}
      />
      <span className="padding-left-sm label-medium normal steel custom-control custom-control-inline">
        {t('common:ConfidenceCode')}
      </span>
    </Form>
  );
};

UploadForm.propTypes = {
  loading: PT.bool,
  onSubmit: PT.func.isRequired,
  settings: PT.shape({
    exludeOutOfBusiness: PT.bool,
    excludeBranches: PT.bool,
    confidenceCode: PT.number
  })
};
