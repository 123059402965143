import { decorate, observable, action, runInAction, computed, toJS } from 'mobx';
import _ from 'lodash';
import * as API from '../api/myprofile';
import * as UserAPI from '../api/login';
import { Storage } from '../utils/storage';
import { StorageDictionary as STG } from '../utils/constants';

const T = {
  settings: 'settings',
  userInfo: 'userInfo',
  changePassword: 'changePassword'
};

export const Modals = {
  close: 'none',
  error: 'error',
  settingSuccess: 'settingSuccess',
  infoSuccess: 'infoSuccess',
  passwordSuccess: 'passwordSuccess'
};

export class SettingStore {
  loading = {
    settings: false,
    userInfo: false,
    changePassword: false
  };

  error = {
    message: '',
    settings: false,
    userInfo: false,
    changePassword: false
  };

  userInfo = null;

  confidenceCode = 10;

  modal = Modals.close;

  load = async ({ userProfile }) => {
    this.reset();
    runInAction(() => {
      this.userInfo = { ...this.userInfo, ...userProfile };
    });
    await this.getSettings();
  };

  reset = () => {
    this.error = {
      message: '',
      settings: false,
      userInfo: false,
      changePassword: false
    };
  };

  setLoading = (type, status) => {
    this.loading[type] = status;
  };

  setError = (type, status, message) => {
    if (message) this.error.message = message;
    this.error[type] = status;
  };

  setModal = type => {
    if (!type) this.modal = Modals.close;
    else this.modal = type;
  };

  uploadConfCode = value => {
    this.confidenceCode = value;
  };

  getSettings = async () => {
    try {
      const result = await API.GetSettings();
      this.uploadConfCode(result.settings.confidenceCode);
    } catch {
      this.setError(T.settings, true);
    }
  };

  updateSettings = async () => {
    this.setLoading(T.settings, true);
    try {
      const result = await API.SaveSettings({ confidenceCode: this.confidenceCode });
      if (!result.ok) throw new Error();
      this.setModal(Modals.settingSuccess);
    } catch {
      this.setError(T.settings, true);
    }
    this.setLoading(T.settings, false);
  };

  updateUserContacts = async userContacts => {
    this.setLoading(T.userInfo, true);
    try {
      const result = await API.UpdateUserInfo({
        email: userContacts.email,
        phone: userContacts.phone
      });
      if (!result.ok) throw new Error();
      this.setError(T.userInfo, false);
      await Storage.store(STG.UserInfo, {
        ...this.userInfo,
        userContacts: { ...this.userInfo.userContacts, ...userContacts }
      });
      this.setModal(Modals.infoSuccess);
    } catch {
      this.setError(T.userInfo, true);
    }
    this.setLoading(T.userInfo, false);
  };

  changePassword = async authInfo => {
    this.setLoading(T.changePassword, true);
    try {
      const result = await UserAPI.ChangePassword({
        userName: this.userInfo.userName,
        oldPassword: authInfo.currentPassword,
        newPassword: authInfo.newPassword
      });
      if (!result.ok) throw new Error();
      this.setError(T.changePassword, false);
      this.setModal(Modals.passwordSuccess);
    } catch {
      this.setError(T.changePassword, true);
    }
    this.setLoading(T.changePassword, false);
  };

  get userContacts() {
    return this.userInfo && toJS(this.userInfo.userContacts);
  }

  get hasErrors() {
    return _.reduce(_.keys(this.error), (acc, key) => acc || (key !== 'message' ? this.error[key] : false), false);
  }
}

decorate(SettingStore, {
  loading: observable,
  error: observable,
  userInfo: observable,
  confidenceCode: observable,
  modal: observable,
  setLoading: action,
  setError: action,
  setModal: action,
  uploadConfCode: action,
  hasErrors: computed
});
