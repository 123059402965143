import { decorate, observable, runInAction, action, computed } from 'mobx';
import _ from 'lodash';
import * as API from '../api/matcher';
import { RoutesDictionary as RT } from '../utils/constants';

const T = {
  info: 'info',
  details: 'details',
  match: 'match',
  skip: 'skip',
  stopMatch: 'stopMatch',
  familyTree: 'familyTree'
};

export const Modals = {
  close: 'none',
  confirm: 'confirm',
  details: 'details',
  success: 'success',
  error: 'error'
};

export class MatchStore {
  loading = {
    info: false,
    details: false,
    match: false,
    skip: false,
    stopMatch: false,
    familyTree: false
  };

  error = {
    message: '',
    info: false,
    details: false,
    match: false,
    skip: false,
    stopMatch: false,
    familyTree: false
  };

  pagingInfo = {
    pageIndex: 0,
    pageSize: 10,
    pagesNumber: 0,
    recordsTotalNumber: 0
  };

  requestData = {};

  subjectsList = [];

  detailList = [];

  matchingSubject = {};

  modal = Modals.close;

  load = async ({ id }) => {
    this.reset();
    runInAction(() => {
      this.requestData.id = id;
    });
    await this.getRequestInfo(id);
    this.getRequestDetails(id);
  };

  reset = () => {
    this.error = {
      message: '',
      info: false,
      details: false,
      match: false,
      skip: false,
      stopMatch: false
    };
    this.requestData = {};
    this.subjectsList = [];
    this.matchingSubject = {};
    this.pagingInfo = {
      pageIndex: 0,
      pageSize: 10,
      pagesNumber: 0,
      recordsTotalNumber: 0
    };
  };

  setLoading = (type, status) => {
    this.loading[type] = status;
  };

  setError = (type, status, message) => {
    if (message) this.error.message = message;
    this.error[type] = status;
  };

  closeError = () => {
    this.error = {
      ...this.error,
      info: false,
      details: false,
      select: false,
      skip: false,
      stopMatch: false
    };
  };

  setModal = type => {
    if (!type) this.modal = Modals.close;
    else this.modal = type;
  };

  getRequestInfo = async requestId => {
    this.setLoading(T.info, true);
    try {
      const result = await API.GetMatchInfo({
        guid: requestId
      });
      runInAction(() => {
        this.requestData = { ...result.subject };
      });
    } catch {
      this.setError(T.info, true);
    }
    this.setLoading(T.info, false);
  };

  getRequestDetails = async requestId => {
    this.setLoading(T.details, true);
    try {
      const result = await API.GetMatchDetails({
        guid: requestId,
        pagingInfo: {
          pageIndex: this.pagingInfo.pageIndex,
          pageSize: this.pagingInfo.pageSize
        }
      });
      runInAction(() => {
        this.subjectsList = result.subjects;
        this.pagingInfo = { ...this.pagingInfo, ...result.pagingInfo };
      });
    } catch {
      this.setError(T.details, true);
    }
    this.setLoading(T.details, false);
  };

  openDetails = subjectId => {
    this.matchingSubject = _.find(this.subjectsList, ['id', subjectId]);
    this.detailList = [];
    this.detailList.push({
      duns: this.matchingSubject.dunsNbr,
      sic: this.matchingSubject.sic,
      hqName: this.matchingSubject.hqName,
      hqAddress: this.matchingSubject.hqAddress,
      hqTown: this.matchingSubject.hqTown,
      hqCountry: this.matchingSubject.hqCountry,

      guName: this.matchingSubject.guName,
      guAddress: this.matchingSubject.guAddress,
      guTown: this.matchingSubject.guTown,
      guCountry: this.matchingSubject.guCountry
    });
    this.setModal(Modals.details);
  };

  matchSubject = async (subjectId, selectedCandidate) => {
    this.setLoading(T.match, true);
    try {
      const result = await API.MatchSubject({
        guid: subjectId,
        candidate: {
          companyName: selectedCandidate.name,
          address: selectedCandidate.address,
          town: selectedCandidate.town,
          cap: selectedCandidate.cap,
          legalStatus: selectedCandidate.legalStatus,
          confidenceCode: selectedCandidate.confidenceCode,
          dunsNbr: selectedCandidate.dunsNbr,
          ctryCd: selectedCandidate.ctryCd,
          mtchGrd: selectedCandidate.mtchGrd,
          nmePctGrd: selectedCandidate.nmePctGrd,
          businessId: selectedCandidate.businessId,
          brInd: selectedCandidate.brInd
        }
      });
      if (!result.ok) throw new Error();
      this.setModal(Modals.close);
      await this.getRequestInfo(this.requestData.id);
      await this.getRequestDetails(this.requestData.id);
    } catch {
      this.setError(T.match, true);
    }
    this.setLoading(T.match, false);
  };

  skipSubject = async subjectId => {
    this.setLoading(T.skip, true);
    try {
      const result = await API.SkipSubject({
        guid: subjectId
      });
      if (!result.ok) throw new Error();
      this.setModal(Modals.close);
      await this.getRequestInfo(this.requestData.id);
      await this.getRequestDetails(this.requestData.id);
    } catch {
      this.setError(T.skip, true);
    }
    this.setLoading(T.skip, false);
  };

  closeMatching = () => {
    window.location.href = `#${RT.HOME}`;
    this.reset();
  };

  stopMatch = async () => {
    this.setLoading(T.stopMatch, true);
    try {
      const result = await API.StopMatch({
        guidMailBox: this.requestData.id
      });
      if (!result.ok) throw new Error();
      this.setModal(Modals.close);
      this.closeMatching();
    } catch {
      this.setError(T.stopMatch, true);
    }
    this.setLoading(T.stopMatch, false);
  };

  changePage = page => {
    this.pagingInfo.pageIndex = page;
    this.getRequestDetails(this.requestData.id);
  };

  changePageSize = size => {
    this.pagingInfo = {
      ...this.pagingInfo,
      pageIndex: 0,
      pageSize: size
    };
    this.getRequestDetails(this.requestData.id);
  };

  get hasErrors() {
    return _.reduce(_.keys(this.error), (acc, key) => acc || (key !== 'message' ? this.error[key] : false), false);
  }

  getFamilyTree = async duns => {
    this.setLoading(T.familyTree, true);
    try {
      const result = await API.GetFamilyTree({ duns });
      runInAction(() => {
        this.detailList.push(result);
      });
    } catch {
      this.setError(T.familyTree, true);
    }
    this.setLoading(T.familyTree, false);
  };
}

decorate(MatchStore, {
  loading: observable,
  error: observable,
  pagingInfo: observable,
  requestData: observable,
  subjectsList: observable,
  matchingSubject: observable,
  modal: observable,
  detailList: observable,
  reset: action,
  setLoading: action,
  setError: action,
  closeError: action,
  setModal: action,
  openDetails: action,
  changePage: action,
  changePageSize: action,
  hasErrors: computed
});
