// eslint-disable-next-line import/no-default-export
export default {
  SiteName: 'Automatic Matching Engine',
  Back: 'Back',
  Browse: 'Browse',
  Continue: 'Continue',
  Search: 'Search',
  Cancel: 'Cancel',
  Ok: 'Ok',
  Undo: 'Undo',
  Confirm: 'Confirm',
  Save: 'Save',
  Delete: 'Delete',
  Edit: 'Edit',
  Add: 'Add',
  Note: 'Note',
  Select: 'Select',
  Hide: 'Hide',
  Show: 'Show',
  Duplicate: 'Duplicate',
  Reset: 'Reset',
  SelectAll: 'Select all',
  DeselectAll: 'Deselect all',
  DoYouWantToContinue: 'Do you want to continue?',
  Yes: 'Yes',
  No: 'No',
  On: 'On',
  Off: 'Off',
  Logout: 'Logout',
  UploadFile: 'Upload',
  PerPage: 'per page',
  exludeOutOfBusiness: 'Exclude Out of Business',
  ExcludeBranches: 'Exclude Branches',
  ConfidenceCode: 'Confidence Code'
};
