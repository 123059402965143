import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { StyledTable as Table, ITableField } from '../../components/Table';

const Status = {
  NEW: 'NEW',
  SKIP: 'SKIP',
  TOMATCH: 'TOMATCH',
  WORKING: 'WORKING',
  MATCHED: 'MATCHED',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR'
};

export const MatcherSubjectsList = ({ fields, data = [], empty, onSelect, loading }) => {
  const { t } = useTranslation('matcher');

  const getStatusClass = status => {
    switch (status) {
      case Status.SKIP:
      case Status.ERROR:
        return 'watermelon';
      case Status.WORKING:
        return 'yellow';
      case Status.MATCHED:
      case Status.COMPLETED:
        return 'tealish-green';
      case Status.TOMATCH:
      case Status.NEW:
      default:
        return 'pale-grey4';
    }
  };

  return (
    <Table>
      <Table.Header fields={fields} t={key => t(`SubjectFields.${key}`)}>
        <Table.HeaderCell label="" width="140" />
      </Table.Header>
      <Table.Body>
        {data.length > 0 || loading ? (
          _.map(data, (item, idx) => (
            <Table.Row key={idx} fields={fields} value={key => item[key]} highlight={key => key === 'fileName'}>
              <Table.Cell custom>
                <div className="actions">
                  <button
                    type="button"
                    className="btn btn-icon dark-slate-blue-bg no-min-width"
                    onClick={() => onSelect(item.id)}
                    disabled={item.status !== Status.TOMATCH && item.status !== Status.NEW}
                  >
                    <i className="fa fa-list-ul white" aria-hidden="true" />
                  </button>
                  <span className="margin-left-md">
                    <i className={`fa fa-circle big ${getStatusClass(item.status)}`} aria-hidden="true" />
                  </span>
                </div>
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.EmptyRow colSpan={fields.length + 1} label={empty} />
        )}
      </Table.Body>
    </Table>
  );
};

MatcherSubjectsList.propTypes = {
  fields: PT.arrayOf(PT.shape(ITableField)).isRequired,
  data: PT.arrayOf(PT.shape({})),
  empty: PT.string,
  onSelect: PT.func,
  loading: PT.bool
};
