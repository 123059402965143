import './common/style.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { StoresDictionary as ST } from '../../utils/constants';
import { Page } from '../../components/Layout';
import { FiltersBox } from '../../components/Filters';
import { Pagination } from '../../components/Pager';
import { BusyElement } from '../../components/Spinner';
import { ModalBox } from '../../components/Modal';
import { RequestElement } from './RequestElement';
import { RQTypes, Status } from './common/constants';
import { Modals } from '../../stores/MyDocsStore';

export const MyDocs = inject(ST.MyDocsStore)(
  observer(({ MyDocsStore }) => {
    const { t } = useTranslation(['mydocs', 'exceptions']);

    const enableRequestCA = (type, status) => {
      if ((type === RQTypes.MBR || type === RQTypes.AMT) && (status === Status.COMPLETED || status === Status.MATCHED))
        return true;
      return false;
    };

    const enableRequestMB = (type, status, errors) => {
      if (!_.includes([RQTypes.CRA0, RQTypes.CRA1], type) && status === Status.COMPLETED && errors > 0) return true;
      return false;
    };

    return (
      <Page boxed className="mydocs-marker" title={t('PageTitle')}>
        <FiltersBox
          filters={MyDocsStore.FiltersMapping}
          drillDowns={MyDocsStore.requestFilters.drillDowns}
          onApply={MyDocsStore.updateFilters}
          onReset={MyDocsStore.updateFilters}
        />
        <div className="block-sep" />
        <div className="result-list">
          <BusyElement block loading={MyDocsStore.loading.list}>
            {_.map(MyDocsStore.requestsList, (request, idx) => (
              <RequestElement
                key={idx}
                request={request}
                loading={MyDocsStore.loading.download}
                loadingRefresh={MyDocsStore.loading.refresh}
                loadingReSubmit={MyDocsStore.loading.resubmit}
                onDownload={MyDocsStore.downloadFile}
                onRework={MyDocsStore.reworkRequest}
                onReSubmit={MyDocsStore.reSubmitRequest}
                resubmitEnabled={MyDocsStore.enableReSubmit}
                requireCA={enableRequestCA(request.type, request.status)}
                requireMB={enableRequestMB(request.type, request.status, request.errorAmount)}
              />
            ))}
            <Pagination
              className="margin-top-lg"
              current={MyDocsStore.pagingInfo.pageIndex}
              total={MyDocsStore.pagingInfo.pagesNumber}
              pageSize={MyDocsStore.pagingInfo.pageSize}
              onChangePage={MyDocsStore.changePage}
              onChangeSize={MyDocsStore.changePageSize}
            >
              <span className="big margin-right-md">
                <span className="dark-slate-blue margin-right-sm">{MyDocsStore.pagingInfo.recordsTotalNumber}</span>
                {t('Total')}
              </span>
            </Pagination>
          </BusyElement>
        </div>
        <ModalBox open={MyDocsStore.modal === Modals.resubmitSuccess} title={t('ReSubmitSuccess')}>
          <p className="big steel padding-sm">{t('ReSubmitSuccessMessage')}</p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="corn-flower-blue-bg white" onClick={() => MyDocsStore.setModal()}>
              {t('common:Ok')}
            </button>
          </div>
        </ModalBox>
        <ModalBox
          withError
          title={t('exceptions:Generic.Error')}
          open={MyDocsStore.error.download || MyDocsStore.error.list || MyDocsStore.error.resubmit}
          onClose={MyDocsStore.closeError}
        >
          <p className="steel big margin-bottom-lg">{MyDocsStore.error.message || t('exceptions:Generic.Message')}</p>
        </ModalBox>
      </Page>
    );
  })
);
