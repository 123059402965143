// eslint-disable-next-line import/no-default-export
export default {
  PageTitle: 'MyDocs',
  Total: 'total requests',
  FileInput: 'input file',
  FileOutput: 'output file',
  Estimated: 'estimated time',
  ReSubmit: 're-submit',
  ReSubmitSuccess: 'Success',
  ReSubmitSuccessMessage: 'Resubmit was completed successfully',
  RequireCA: 'require credit assessment',
  RequireMB: 'go to manual browsing',
  RequestFields: {
    InsertDate: 'requested on',
    Username: 'user',
    Status: 'status',
    Type: 'type',
    InputRecords: 'input records',
    ProcessedRecords: 'processed records',
    MatchedRecords: 'matched business',
    ConfidenceCode: 'Confidence Code',
    ExcludeOOB: 'Exclude Out Of Business',
    ExcludeBranches: 'Exclude Branches'
  }
};
