module.exports = {
  GetMailBox: {
    url: '/Cacib/GetMailBox',
    type: 'POST',
    fake: false
  },
  GetMailBoxDetails: {
    url: '/Cacib/GetMailBoxDetails',
    type: 'POST',
    fake: false
  },
  UpdateMailBoxDetails: {
    url: '/Cacib/UpdateMailBoxDetails',
    type: 'POST',
    fake: false
  },
  UpdateMailBoxDetailsSkip: {
    url: '/Cacib/UpdateMailBoxDetailsSkip',
    type: 'POST',
    fake: false
  },
  UpdateRemainingMailboxDetails: {
    url: '/Cacib/UpdateRemainingMailboxDetails',
    type: 'POST',
    fake: false
  },
  GetFamilyTree: {
    url: '/Cacib/GetFamilyTree',
    type: 'POST',
    fake: false
  }
};
