import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import _ from 'lodash';
import en from './en-US';

const debug = process.env.NODE_ENV === 'development';

i18n.use(initReactI18next).init({
  resources: { en },
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  },
  saveMissing: debug,
  missingKeyHandler: (lngs, ns, key) => {
    const message = `Translation for key: ${ns}:${key} is missing in languages: ${_.join(lngs, ',')}`;
    if (debug) {
      throw new Error(message);
    } else {
      // eslint-disable-next-line no-console
      console.warn(message);
    }
  }
});

// eslint-disable-next-line import/no-default-export
export default i18n;
