import Api from './api';
import { callApi } from '../fetch';

export const Login = async loginInfo => {
  const response = await callApi(Api.Login, {
    bffCall: false,
    body: JSON.stringify(loginInfo)
  }).catch(error => {
    throw new Error(error);
  });
  return response;
};

export const ResetPassword = async userInfo => {
  const response = await callApi(Api.ResetPassword, {
    bffCall: false,
    body: JSON.stringify(userInfo)
  }).catch(error => {
    throw new Error(error);
  });
  return response;
};

export const ChangePassword = async inputData => {
  const response = await callApi(Api.ChangePassword, {
    bffCall: false,
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.changePasswordResult && response.changePasswordResult.isSuccess
  };
};
