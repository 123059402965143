module.exports = {
  Login: {
    url: '/Account/Login',
    type: 'POST',
    fake: false
  },
  ResetPassword: {
    url: '/Account/ResetPassword',
    type: 'POST',
    fake: false
  },
  ChangePassword: {
    url: '/Account/ChangePassword',
    type: 'POST',
    fake: false
  }
};
