import { decorate, observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import * as API from '../api/mydocs';
import { downloadData } from '../utils';
import { RoutesDictionary as RT } from '../utils/constants';

const T = {
  list: 'list',
  download: 'download',
  rework: 'rework',
  resubmit: 'resubmit',
  refresh: 'refresh'
};

export const Modals = {
  close: 'none',
  resubmitSuccess: 'resubmitSuccess'
};

export class MyDocsStore {
  Interval = null;

  loading = {
    refresh: false,
    list: false,
    rework: false,
    resubmit: {
      id: null,
      load: false
    },
    download: {
      id: null,
      type: null,
      load: false
    }
  };

  error = {
    message: '',
    list: false,
    download: false,
    rework: false,
    resubmit: false
  };

  modal = Modals.close;

  userInfo = null;

  pagingInfo = {
    pageIndex: 0,
    pageSize: 10,
    pagesNumber: 0,
    recordsTotalNumber: 0
  };

  FiltersMapping = [
    { type: 'drilldown', width: 3, key: 'state', label: 'status' },
    { type: 'drilldown', width: 3, key: 'username', label: 'user name' },
    { type: 'text', width: 3, key: 'requestName', label: 'request name' },
    { type: 'daterange', width: 3, key: 'insertDate', label: 'insert date from - to' }
  ];

  requestFilters = {
    requestName: '',
    insertDate: { start: '', end: '' },
    drillDowns: {}
  };

  requestsList = [];

  refreshData = async () => {
    if (window.location.hash !== `#${RT.MYDOCS}`) clearInterval(this.Interval);
    else {
      this.setLoading(T.refresh, true);
      await this.getRequests(true);
      this.setLoading(T.refresh, false);
    }
  };

  load = async ({ userProfile }) => {
    this.reset();
    this.Interval = setInterval(this.refreshData, 20000);
    this.userInfo = userProfile;
    this.getRequests();
  };

  reset = () => {
    this.closeError();
    this.requestsList = [];
    this.pagingInfo = {
      pageIndex: 0,
      pageSize: 10,
      pagesNumber: 0,
      recordsTotalNumber: 0
    };
  };

  setLoading = (type, status) => {
    this.loading[type] = status;
  };

  setError = (type, status, message) => {
    if (message) this.error.message = message;
    this.error[type] = status;
  };

  setModal = type => {
    if (!type) this.modal = Modals.close;
    else this.modal = type;
  };

  closeError = () => {
    this.error = {
      ...this.error,
      list: false,
      download: false,
      resubmit: false
    };
  };

  updateFilters = inputFilters => {
    this.requestFilters = { ...this.requestFilters, ...inputFilters };
    this.pagingInfo.pageIndex = 0;
    this.getRequests();
  };

  getQueryFilters = () => {
    return {
      requestName: this.requestFilters.requestName,
      dateFrom: this.requestFilters.insertDate.start,
      dateTo: this.requestFilters.insertDate.end,
      filters: { ...this.requestFilters.drillDowns }
    };
  };

  getRequests = async hidden => {
    this.setLoading(T.list, !hidden);
    try {
      const updatedFilters = this.getQueryFilters();
      const result = await API.GetDocuments({
        ...updatedFilters,
        pagingInfo: {
          pageIndex: this.pagingInfo.pageIndex,
          pageSize: this.pagingInfo.pageSize
        }
      });
      runInAction(() => {
        this.requestsList = result.requestsList;
        this.requestFilters = {
          ...this.requestFilters,
          drillDowns: _.mapValues(result.drillDowns, (dd, key) => ({
            options: _.map(dd.options, opt => {
              if (!this.requestFilters.drillDowns[key]) return { ...opt };
              const prevOption = _.find(this.requestFilters.drillDowns[key].options, ['code', opt.code]);
              return { ...opt, isSelected: prevOption && prevOption.isSelected };
            })
          }))
        };
        this.pagingInfo = { ...this.pagingInfo, ...result.pagingInfo };
      });
    } catch (error) {
      this.setError(T.list, true);
    }
    this.setLoading(T.list, false);
  };

  changePage = page => {
    this.pagingInfo.pageIndex = page;
    this.getRequests();
  };

  changePageSize = size => {
    this.pagingInfo = {
      ...this.pagingInfo,
      pageIndex: 0,
      pageSize: size
    };
    this.getRequests();
  };

  downloadFile = async (getInputFile, fileId, matchType, fileName) => {
    this.setLoading(T.download, {
      id: fileId,
      type: getInputFile ? 'input' : 'output',
      load: true
    });
    try {
      let result;
      if (getInputFile) result = await API.GetInputFile({ guidMailBox: fileId });
      else result = await API.GetOutputFile({ guidMailBox: fileId, processing: matchType });
      if (!result) throw new Error();
      const name = getInputFile ? fileName : `Output_${fileName}`;
      downloadData(result.fileData, 'application/vnd.ms-excel', name);
    } catch {
      this.setError(T.download, true, 'There was an error on download file service');
    }
    this.setLoading(T.download, { id: null, type: null, load: false });
  };

  reworkRequest = async (type, requestId, requestStatus, requestType) => {
    this.setLoading(T.rework, true);
    try {
      if (type === 0) {
        const result = await API.RequireManualBrowsing({
          guid: requestId,
          state: requestStatus,
          processing: requestType
        });
        if (!result.ok) throw new Error();
      } else if (type === 1) {
        const result = await API.RequireCreditAssessment({
          guid: requestId,
          state: requestStatus,
          processing: requestType
        });
        if (!result.ok) throw new Error();
      }
      this.getRequests();
    } catch (error) {
      this.setError(T.rework, true);
    }
    this.setLoading(T.rework, false);
  };

  reSubmitRequest = async mailBoxId => {
    this.setLoading(T.resubmit, { id: mailBoxId, load: true });
    try {
      const result = await API.RestartProcessingMatchDocument({ guidMailBox: mailBoxId });
      if (!result.ok) throw new Error();
      this.setModal(Modals.resubmitSuccess);
    } catch {
      this.setError(T.resubmit, true, 'There was an error on re-submit service');
    }
    this.setLoading(T.resubmit, { id: null, load: false });
  };

  get enableReSubmit() {
    if (!this.userInfo) return false;
    return this.userInfo.canRestartProcessingMatchDocument || false;
  }
}

decorate(MyDocsStore, {
  loading: observable,
  error: observable,
  modal: observable,
  pagingInfo: observable,
  requestFilters: observable,
  requestsList: observable,
  setLoading: action,
  setError: action,
  setModal: action,
  closeError: action,
  updateFilters: action,
  changePage: action,
  changePageSize: action
});
