import './style.scss';

import React from 'react';
import { inject } from 'mobx-react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { RoutesDictionary as RT, StoresDictionary as ST } from '../../utils/constants';
import Logo from '../../img/logo_cribis2.png';

// eslint-disable-next-line react/prop-types
const UserButtonComponent = ({ onClick, children }, ref) => (
  <button
    ref={ref}
    type="button"
    className="user-btn normal dusty-orange upcas bold margin-bottom-sm"
    onClick={e => onClick(e)}
  >
    {children}
  </button>
);

const UserButton = React.forwardRef(UserButtonComponent);

export const Header = inject(ST.UserStore)(({ UserStore, userProfile }) => {
  const route = link => {
    if (link === 'logout') UserStore.userLogout();
    else window.location.href = link;
  };

  return (
    <header className="ccomx-header-marker">
      <Navbar>
        <Container>
          <Navbar.Brand href={`#${RT.HOME}`} className="logo no-margin">
            <img src={Logo} alt="logo" />
          </Navbar.Brand>
          <div className="header-right pull-right align-right">
            <Dropdown onSelect={link => route(link)}>
              <Dropdown.Toggle as={UserButton}>
                <span className="icon-user dark-slate-blue" /> {userProfile && userProfile.userName}
              </Dropdown.Toggle>
              <Dropdown.Menu className="super-colors">
                <Dropdown.Item eventKey={`#${RT.MYPROFILE}`}>Settings</Dropdown.Item>
                <Dropdown.Item eventKey="logout">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Nav>
              <Nav.Link href={`#${RT.HOME}`}>Home</Nav.Link>
              <Nav.Link href={`#${RT.MYDOCS}`}>MyDocs</Nav.Link>
              {userProfile && userProfile.hasCacibUsageRole && <Nav.Link href={`#${RT.STATEMENT}`}>Statement</Nav.Link>}
            </Nav>
          </div>
        </Container>
      </Navbar>
    </header>
  );
});
