import './common/style.scss';

import React from 'react';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import { StoresDictionary as ST } from '../../utils/constants';
import { Page, SectionContainer, BoxTitle } from '../../components/Layout';
import { ModalBox } from '../../components/Modal';
import { Modals } from '../../stores/MatchStotre';
import { BusyElement } from '../../components/Spinner';
import { Pagination } from '../../components/Pager';
import { MatcherSubjectsList } from './MatcherSubjectsList';
import { SubjectMatcher } from './SubjectMatcher';
import { MatcherHeader } from './MatcherHeader';

const MatcherFields = [
  { label: 'Id', key: 'id', width: '50' },
  { label: 'Name', key: 'name', align: 'left' },
  { label: 'DebtorCode', key: 'debtorCode' },
  { label: 'BusinessId', key: 'businessId' },
  { label: 'CountryCode', key: 'countryCode' },
  { label: 'Duns', key: 'duns' }
];

export const FileMatcher = inject(ST.MatchStore)(
  observer(({ MatchStore }) => {
    const { t } = useTranslation(['matcher', 'common', 'exceptions']);
    return (
      <Page boxed className="file-matcher-marker" title={t('PageTitle')}>
        <MatcherHeader
          request={MatchStore.requestData}
          onClose={MatchStore.closeMatching}
          onStop={() => MatchStore.setModal(Modals.confirm)}
          loadingAction={MatchStore.loading.stopMatch}
          loading={MatchStore.loading.info}
        />
        <SectionContainer>
          <Row>
            <Col sm={12}>
              <BoxTitle className="margin-bottom-lg" title={t('SubjectsList')} />
            </Col>
          </Row>
          <BusyElement block loading={MatchStore.loading.details || MatchStore.loading.info}>
            <Row>
              <Col sm={12}>
                <MatcherSubjectsList
                  fields={MatcherFields}
                  data={MatchStore.subjectsList.slice()}
                  onSelect={MatchStore.openDetails}
                  loading={MatchStore.loading.details}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Pagination
                  className="margin-y-sm"
                  current={MatchStore.pagingInfo.pageIndex}
                  total={MatchStore.pagingInfo.pagesNumber}
                  pageSize={MatchStore.pagingInfo.pageSize}
                  onChangePage={MatchStore.changePage}
                  onChangeSize={MatchStore.changePageSize}
                >
                  <span className="big margin-right-md">
                    <span className="dark-slate-blue margin-right-sm">{MatchStore.pagingInfo.recordsTotalNumber}</span>
                    {t('Total')}
                  </span>
                </Pagination>
              </Col>
            </Row>
          </BusyElement>
        </SectionContainer>
        <ModalBox
          size="xl"
          closeButton
          className="match-details-modal"
          open={MatchStore.modal === Modals.details}
          onClose={() => MatchStore.setModal(Modals.close)}
          title={t('MatcherModal')}
        >
          <SubjectMatcher
            subject={MatchStore.matchingSubject}
            onSelect={MatchStore.matchSubject}
            onSkip={MatchStore.skipSubject}
            loadingMatch={MatchStore.loading.match}
            loadingSkip={MatchStore.loading.skip}
            getFamilyTree={MatchStore.getFamilyTree}
            loadingFamilyTree={MatchStore.loading.familyTree}
            detailList={MatchStore.detailList}
          />
        </ModalBox>
        <ModalBox open={MatchStore.modal === Modals.confirm} title={t('EndBrowsing')}>
          <p className="big steel">
            {t('EndBrowsingConfirm')}
            <br />
            {t('EndBrowsingAlert')}
          </p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="steel-bg white" onClick={MatchStore.setModal}>
              {t('common:Undo')}
            </button>
            <button type="button" className="corn-flower-blue-bg white" onClick={MatchStore.stopMatch}>
              {t('common:Confirm')}
            </button>
          </div>
        </ModalBox>
        <ModalBox
          withError
          title={t('exceptions:Generic.Error')}
          open={MatchStore.hasErrors}
          onClose={MatchStore.closeError}
        >
          <p className="steel big margin-bottom-lg">{MatchStore.error.message || t('exceptions:Generic.Message')}</p>
        </ModalBox>
      </Page>
    );
  })
);
