import './style.scss';

import React from 'react';
import PT from 'prop-types';
import { Pager } from './pagination';
import { PageSize } from './pagesize';

export const Pagination = ({
  className,
  current,
  total,
  limit,
  nosize,
  pageSize,
  sizeList,
  onChangePage,
  onChangeSize,
  children
}) =>
  !total ? null : (
    <div className={`pagination-container ${className || ''}`}>
      {children}
      <Pager {...{ current, total, limit }} onChange={onChangePage} />
      {!nosize && <PageSize selected={pageSize} sizes={sizeList} onChange={onChangeSize} />}
    </div>
  );

Pagination.propTypes = {
  className: PT.string,
  current: PT.number.isRequired,
  total: PT.number.isRequired,
  onChangePage: PT.func.isRequired,
  onChangeSize: PT.func.isRequired,
  nosize: PT.bool,
  limit: PT.number,
  pageSize: PT.number,
  sizeList: PT.arrayOf(PT.number),
  children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)])
};
