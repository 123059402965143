import React from 'react';
import PT from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BusyElement } from '../../components/Spinner';
import { Spinner } from '../../components/Spinner';
import { IRequest } from './common/types';
import { SubjectDetails } from './SubjectDetails';
import { CandidatesList } from './CandidatesList';

export const SubjectMatcher = withTranslation('matcher')(
  ({ t, subject, onSkip, onSelect, loadingMatch, loadingSkip, getFamilyTree, loadingFamilyTree, detailList }) => (
    <>
      <div className="sect-head margin-bottom-md">
        <h4 className="normal steel1 upcas no-margin">{t('InputSubject')}</h4>
        <button
          type="button"
          className="button-big std-label upcas silver4-bg"
          onClick={() => onSkip(subject.uid)}
          disabled={loadingMatch || loadingSkip}
        >
          <Spinner inline size={1} loading={loadingSkip} className="margin-right-xs" />
          {t('SkipMatch')}
        </button>
      </div>
      <SubjectDetails subject={subject} />
      <h4 className="normal steel1 upcas margin-bottom-md">{t('SuggestedSubjects')}</h4>
      <span style={{ position: 'fixed', zIndex: 1 }}>
        <BusyElement className="busy-element" loading={loadingFamilyTree} size={4} />
      </span>
      <CandidatesList
        candidates={subject.matchList.slice()}
        loading={loadingMatch}
        disableSelect={loadingSkip}
        onSelect={candidate => onSelect(subject.uid, candidate)}
        getFamilyTree={getFamilyTree}
        detailList={detailList}
      />
    </>
  )
);

SubjectMatcher.propTypes = {
  subject: PT.shape(IRequest).isRequired,
  onSelect: PT.func,
  onSkip: PT.func,
  loadingMatch: PT.bool,
  loadingSkip: PT.bool
};
