import React from 'react';
import PT from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Spinner } from '../../components/Spinner';
import { IRequest } from './common/types';
import { Status, RQTypes } from './common/constants';

export const RequestElement = withTranslation('mydocs')(
  ({
    t,
    request,
    loading,
    loadingRefresh,
    loadingReSubmit,
    onDownload,
    onRework,
    onReSubmit,
    resubmitEnabled,
    requireCA,
    requireMB
  }) => (
    <div className="shadowed-box result-box margin-top-lg">
      <div className="inner-container">
        {/* Output File */}
        <div className="inset-box">
          <div className="upcas link-silver-small margin-bottom-sm">{request.fileName}</div>
          <button
            type="button"
            className="output-file one-line upcas button-big dark-slate-blue-bg"
            onClick={() => onDownload(false, request.id, request.type, request.fileName)}
            disabled={loading.load || request.status === Status.NEW || request.status === Status.WORKING}
          >
            <Spinner
              inline
              size={1}
              loading={loading.type === 'output' && loading.id === request.id}
              className="margin-right-sm"
            >
              <i className="icon-download big margin-right-sm" aria-hidden="true" />
            </Spinner>
            {t('FileOutput')}
          </button>
          {request.status === Status.WORKING && (
            <div className="estimated one-line alert-info smaller steel margin-top-sm padding-xs align-center">
              {t('Estimated')}: {request.deliveryTime}
            </div>
          )}
          {/* Input File and Actions */}
          <div className="actions">
            <div className="buttons">
              <button
                type="button"
                className="input-file button-big smaller upcas corn-flower-blue-bg margin-top-sm"
                onClick={() => onDownload(true, request.id, null, request.fileName)}
                disabled={loading.load}
              >
                <Spinner
                  inline
                  size={1}
                  loading={loading.type === 'input' && loading.id === request.id}
                  className="margin-right-sm"
                />
                {t('FileInput')}
              </button>
              {resubmitEnabled && request.status === Status.WORKING && (
                <button
                  type="button"
                  className="re-submit button-big smaller upcas dark-slate-blue-bg margin-left-sm"
                  onClick={() => onReSubmit(request.id)}
                  disabled={loadingReSubmit.load}
                >
                  <Spinner
                    inline
                    size={1}
                    loading={loadingReSubmit.load && loadingReSubmit.id === request.id}
                    className="margin-right-sm"
                  />
                  {t('ReSubmit')}
                </button>
              )}
            </div>
            {requireCA && (
              <a
                href="/"
                className="capit inline-block link-orange link-with-icon white-bg"
                onClick={e => {
                  e.preventDefault();
                  onRework(1, request.id, request.status, request.type);
                }}
              >
                {t('RequireCA')}
                <i className="icon-arrow_right" aria-hidden="true" />
              </a>
            )}
            {requireMB && (
              <a
                href="/"
                className="capit inline-block link-orange link-with-icon white-bg"
                onClick={e => {
                  e.preventDefault();
                  onRework(0, request.id, request.status, request.type);
                }}
              >
                {t('RequireMB')}
                <i className="icon-arrow_right" aria-hidden="true" />
              </a>
            )}
          </div>
        </div>

        {/* Request Informations */}
        <div className="inset-box req-info">
          <div className="ins-col">
            <div>
              <div className="one-line upcas link-silver-small">{t('RequestFields.InsertDate')}</div>
              <div className="one-line">{request.insertDate}</div>
            </div>
            <div>
              <div className="one-line upcas link-silver-small">{t('RequestFields.Username')}</div>
              <div className="one-line">{request.userName}</div>
            </div>
          </div>
          <div className="ins-col">
            <div>
              <div className="one-line upcas link-silver-small">{t('RequestFields.Status')}</div>
              <div className="one-line">{request.status}</div>
            </div>
            <div>
              <div className="one-line upcas link-silver-small">{t('RequestFields.Type')}</div>
              <div className="one-line capit">{RQTypes[request.type] || ''}</div>
            </div>
          </div>
        </div>
        {/* Processing Informations */}
        <div className="inset-box process-info">
          <div className="ins-row">
            <div className="upcas link-silver-small">{t('RequestFields.InputRecords')}</div>
            <div>
              <Spinner inline size={1} loading={loadingRefresh} className="ins-spin">
                {request.totalAmount}
              </Spinner>
            </div>
          </div>
          <div className="ins-row">
            <div className="upcas link-silver-small">{t('RequestFields.ProcessedRecords')}</div>
            <div>
              <Spinner inline size={1} loading={loadingRefresh} className="ins-spin">
                {request.processedAmount}
              </Spinner>
            </div>
          </div>
          <div className="ins-row">
            <div className="upcas link-silver-small">{t('RequestFields.MatchedRecords')}</div>
            <div>
              <Spinner inline size={1} loading={loadingRefresh} className="ins-spin">
                {request.matchAmount}
              </Spinner>
            </div>
          </div>
        </div>
        {/* Processing options */}
        <div className="inset-box process-info">
          <div className="ins-row">
            <div className="upcas link-silver-small">{t('RequestFields.ConfidenceCode')}</div>
            <div>
              <Spinner inline size={1} loading={loadingRefresh} className="ins-spin">
                {request.confidenceCode}
              </Spinner>
            </div>
          </div>
          <div className="ins-row">
            <div className="upcas link-silver-small">{t('RequestFields.ExcludeOOB')}</div>
            <div>
              <Spinner inline size={1} loading={loadingRefresh} className="ins-spin">
                {request.excludeOOB ? 'yes' : 'no'}
              </Spinner>
            </div>
          </div>
          <div className="ins-row">
            <div className="upcas link-silver-small">{t('RequestFields.ExcludeBranches')}</div>
            <div>
              <Spinner inline size={1} loading={loadingRefresh} className="ins-spin">
                {request.excludeBranches ? 'yes' : 'no'}
              </Spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
);

RequestElement.propTypes = {
  request: PT.shape(IRequest).isRequired,
  loading: PT.shape({
    id: PT.string,
    type: PT.string,
    load: PT.bool
  }),
  loadingRefresh: PT.bool,
  loadingReSubmit: PT.shape({
    id: PT.string,
    load: PT.bool
  }),
  onDownload: PT.func,
  onRework: PT.func,
  onReSubmit: PT.func,
  resubmitEnabled: PT.bool,
  requireCA: PT.bool,
  requireMB: PT.bool
};
