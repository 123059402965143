import './common/style.scss';

import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { StoresDictionary as ST } from '../../utils/constants';
import { Page, SectionContainer, BoxTitle } from '../../components/Layout';
import { UploadForm } from './UploadForm';
import { Pagination } from '../../components/Pager';
import { ModalBox } from '../../components/Modal';
import { Modals } from '../../stores/HomeStore';
import { BusyElement } from '../../components/Spinner';
import { ManualBrowsingList } from './ManualBrowsingList';

const ManualBrowsingFields = [
  { label: 'FileName', key: 'fileName' },
  { label: 'Status', key: 'status' },
  { label: 'Date', key: 'insertDate' },
  { label: 'Matched', key: 'matchAmount' },
  { label: 'NotMatched', key: 'noMatchAmount' }
];

export const Home = inject(ST.HomeStore)(
  observer(({ HomeStore }) => {
    const { t } = useTranslation(['home', 'common', 'exceptions']);
    return (
      <Page boxed className="home-marker" title={t('common:SiteName')}>
        <SectionContainer className="margin-top-xl">
          <Row>
            <Col md={4} sm={12}>
              <BoxTitle className="margin-bottom-lg" title={t('DownloadArea')} />
              <div className="user-links">
                <a
                  href="Download/UserManual.pdf"
                  target="_blank"
                  className="label-corn-flower-blue upcas margin-bottom-md"
                >
                  <i className="icon-report2 margin-right-sm" aria-hidden="true" />
                  {t('UserManual')}
                </a>
                <a href="Download/Template.xlsx" className="label-corn-flower-blue upcas">
                  <i className="icon-report2 margin-right-sm" aria-hidden="true" />
                  {t('DownloadTemplate')}
                </a>
              </div>
            </Col>
            <Col md={8} sm={12}>
              <BoxTitle className="margin-bottom-lg" title={t('RequestsUpload')} />
              <UploadForm
                loading={HomeStore.loading.submit}
                onSubmit={HomeStore.submitFile}
                settings={HomeStore.settings}
              />
            </Col>
          </Row>
        </SectionContainer>
        <SectionContainer className="margin-top-xl">
          <Row>
            <Col sm={12}>
              <BoxTitle className="margin-bottom-lg" title={t('ManualBrowsing')} />
            </Col>
          </Row>
          <BusyElement block loading={HomeStore.loading.list}>
            <Row>
              <Col sm={12}>
                <ManualBrowsingList
                  fields={ManualBrowsingFields}
                  data={HomeStore.matchFileList.slice()}
                  onSelect={HomeStore.openMatching}
                  loading={HomeStore.loading.list}
                  empty={t('EmptyLabel')}
                  t={t}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Pagination
                  className="margin-y-sm"
                  current={HomeStore.pagingInfo.pageIndex}
                  total={HomeStore.pagingInfo.pagesNumber}
                  pageSize={HomeStore.pagingInfo.pageSize}
                  onChangePage={HomeStore.changePage}
                  onChangeSize={HomeStore.changePageSize}
                >
                  <span className="big margin-right-md">
                    <span className="dark-slate-blue margin-right-sm">{HomeStore.pagingInfo.recordsTotalNumber}</span>
                    {t('Total')}
                  </span>
                </Pagination>
              </Col>
            </Row>
          </BusyElement>
        </SectionContainer>
        <ModalBox open={HomeStore.modal === Modals.success} title={t('UploadSuccess')}>
          <p className="big steel padding-sm">{t('SuccessMessage')}</p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="corn-flower-blue-bg white" onClick={HomeStore.goToMyDocs}>
              {t('common:Ok')}
            </button>
          </div>
        </ModalBox>
        <ModalBox open={HomeStore.modal === Modals.error} title={t('UploadError')}>
          <p className="alert-danger padding-md margin-bottom-md">{HomeStore.error.message}</p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="corn-flower-blue-bg white" onClick={() => HomeStore.setModal()}>
              {t('common:Ok')}
            </button>
          </div>
        </ModalBox>
        <ModalBox
          withError
          title={t('exceptions:Generic.Error')}
          open={HomeStore.error.submit || HomeStore.error.list}
          onClose={HomeStore.closeError}
        >
          <p className="steel big margin-bottom-lg">{HomeStore.error.message || t('exceptions:Generic.Message')}</p>
        </ModalBox>
      </Page>
    );
  })
);
