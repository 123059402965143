import PT from 'prop-types';

export const IRequest = {
  uid: PT.string,
  id: PT.string,
  status: PT.string,
  debtorCode: PT.string,
  name: PT.string,
  address: PT.string,
  city: PT.string,
  postCode: PT.string,
  countryCode: PT.string,
  duns: PT.string,
  dunsNbr: PT.string,
  guRatingNeeded: PT.string,
  county: PT.string,
  businessId: PT.string,
  matchList: PT.shape({})
};

export const ICandidate = {
  name: PT.string,
  address: PT.string,
  town: PT.string,
  cap: PT.string,
  legalStatus: PT.string,
  confidenceCode: PT.string,
  dunsNbr: PT.string,
  ctryCd: PT.string,
  mtchGrd: PT.string,
  nmePctGrd: PT.string,
  businessId: PT.string,
  brInd: PT.string
};
