import './style.scss';

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../img/logo_cribis2.png';
import LogoDB from '../../img/dun_bradstreet_large.png';

export const Footer = () => (
  <footer className="ccomx-footer-marker">
    <Container>
      <Row>
        <Col sm={6} className="">
          <a href="/" className="logo">
            <img src={Logo} alt="logo" />
          </a>
        </Col>
        <Col sm={6} className="align-right">
          <a href="/" className="logo">
            <img src={LogoDB} alt="logo" />
          </a>
        </Col>
      </Row>
    </Container>
  </footer>
);
