import _ from 'lodash';
import moment from 'moment';

export const numberToabbreviateString = (number, decimals = 0, units = ['k', 'mln', 'bln', 'tln']) => {
  const isNegative = number < 0;
  let absNumber = Math.abs(number);
  const decPlaces = 10 ** decimals;

  for (let i = units.length - 1; i >= 0; i -= 1) {
    const size = 10 ** ((i + 1) * 3);
    if (size <= absNumber) {
      absNumber = Math.round((absNumber * decPlaces) / size) / decPlaces;
      if (absNumber === 1000 && i < units.length - 1) {
        absNumber = 1;
        i += 1;
      }
      absNumber += units[i];
      break;
    }
  }

  return `${isNegative ? '-' : ''} ${absNumber}`;
};

export const toMilionString = number => {
  const inMilion = (Math.abs(Number(number)) / 1.0e6).toFixed(2);

  return `${inMilion === '0.00' ? '0' : inMilion} mln`;
};

export const getElementPosition = el => {
  let element = el;
  let xPos = 0;
  let yPos = 0;
  while (element) {
    if (element.tagName === 'BODY') {
      // deal with browser quirks with body/window/document and page scroll
      const xScroll = element.scrollLeft || document.documentElement.scrollLeft;
      const yScroll = element.scrollTop || document.documentElement.scrollTop;
      xPos += element.offsetLeft - xScroll + element.clientLeft;
      yPos += element.offsetTop - yScroll + element.clientTop;
    } else {
      // for all other non-BODY elements
      xPos += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPos += element.offsetTop - element.scrollTop + element.clientTop;
    }
    element = element.offsetParent;
  }
  return {
    x: xPos,
    y: yPos
  };
};

export const wait = ms => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};

export function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export const downloadData = async (bytearray, mimeType, name) => {
  const data = s2ab(atob(bytearray));
  const blob = new Blob([data], {
    type: mimeType
  });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    await wait(2500);
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
};

export function format(...args) {
  const strs = Array.prototype.slice.call(args, 1);
  return _.replace(args[0], /\{(\d+)\}/g, (match, index) => {
    return strs[index];
  });
}

export function getReducedString(string, n) {
  return string.length > n ? `${string.substring(0, n)}...` : string;
}

export function matchDateTimeFormat(date) {
  return date.match(/^((0[1-9]|[12][0-9]|3[01])\/(0[0-9]|1[0-2])\/\d\d\d\d) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/g);
}

export function formatMomentDate(momentDate) {
  return momentDate.format('DD/MM/YYYY HH:mm');
}

export function matchTimeFormat(time) {
  return time.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g);
}

export function isNormalInteger(str) {
  const n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
}

export function isBeforeToday(date) {
  return date < moment();
}

export function isHex(str) {
  const regexp = /^([a-f0-9]{6}|[a-f0-9]{3})$/i;
  return regexp.test(str);
}

export function isBusinessDay(date) {
  const newdate = moment(date);
  return newdate.isoWeekday() !== 6 && newdate.isoWeekday() !== 7;
}

export function addBusinessDays(date, days) {
  let newdate = moment(date);
  let newdays = days;
  while (newdays > 0) {
    newdate = newdate.add(1, 'days');
    if (isBusinessDay(newdate)) newdays -= 1;
  }
  return newdate;
}
