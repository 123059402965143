module.exports = {
  GetSettings: {
    url: '/Cacib/GetSettings',
    type: 'GET',
    fake: false
  },
  GetHomeMailBoxes: {
    url: '/Cacib/GetHomeMailBoxes',
    type: 'POST',
    fake: false
  },
  UploadMailBoxInputFile: {
    url: '/Cacib/UploadMailBoxInputFile',
    type: 'POST',
    fake: false
  }
};
