import React from 'react';
import PT from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Spinner } from '../../components/Spinner';

export const MatcherHeader = withTranslation('matcher')(({ t, request, onClose, onStop, loadingAction, loading }) => (
  <div className="match-header padding-lg margin-top-xl">
    <Row>
      <Col lg={6} sm={12} className="b-right">
        <div className="inp-title corn-flower-blue upcas">
          <Spinner inline size={1} loading={loading} className="dark-slate-blue margin-right-xs">
            <i className="icon-documenti bigger dark-slate-blue margin-right-xs" aria-hidden="true" />
          </Spinner>
          {t('FileInput')}
        </div>
        <div className="request-info">
          <div className="box-info">
            <div className="smaller upcas bold dark-slate-blue">{t('RequestFields.Filename')}</div>
            <div className="dusty-orange">{request.fileName || ''}</div>
          </div>
          <div className="box-info">
            <div className="smaller upcas bold dark-slate-blue">{t('RequestFields.Status')}</div>
            <div className="dusty-orange">{request.status || ''}</div>
          </div>
          <div className="box-info">
            <div className="smaller upcas bold dark-slate-blue">{t('RequestFields.InsertDate')}</div>
            <div className="dusty-orange">{request.insertDate || ''}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} sm={12} className="match-actions">
        <div className="match-info margin-left-md">
          <div className="box">
            <div className="smaller upcas bold dark-slate-blue">{t('RequestFields.Matched')}</div>
            <div className="dusty-orange">{request.matchAmount || '0'}</div>
          </div>
          <div className="box">
            <div className="smaller upcas bold dark-slate-blue">{t('RequestFields.NotMatched')}</div>
            <div className="dusty-orange">{request.noMatchAmount || '0'}</div>
          </div>
        </div>
        <div className="match-buttons align-right">
          <OverlayTrigger placement="top" overlay={<Tooltip>{t('CloseTooltip')}</Tooltip>}>
            <button
              type="button"
              className="button-big std-label upcas corn-flower-blue-bg"
              onClick={onClose}
              disabled={loading}
            >
              {t('Close')}
            </button>
          </OverlayTrigger>
          <button
            type="button"
            className="button-big std-label upcas corn-flower-blue-bg margin-left-md"
            onClick={onStop}
            disabled={loading || loadingAction}
          >
            <Spinner inline size={1} loading={loadingAction} className="margin-right-xs" />
            {t('EndBrowsing')}
          </button>
        </div>
      </Col>
    </Row>
  </div>
));

MatcherHeader.propTypes = {
  request: PT.shape({
    fileName: PT.string,
    status: PT.string,
    insertDate: PT.string,
    matchAmount: PT.number,
    noMatchAmount: PT.number
  }),
  onClose: PT.func,
  onStop: PT.func,
  loadingAction: PT.bool,
  loading: PT.bool
};
