import Api from './api';
import { callApi } from '../fetch';

export const GetSettings = async () => {
  const response = await callApi(Api.GetSettings).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful,
    settings: response.settings,
    userInfo: {
      name: response.name,
      surname: response.surname,
      role: response.role,
      email: response.email,
      phone: response.phoneNumber
    }
  };
};

export const UpdateUserInfo = async inputData => {
  const response = await callApi(Api.UpdateUserInfo, {
    bffCall: false,
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.updateUserResult && response.updateUserResult.isSuccess
  };
};

export const SaveSettings = async inputData => {
  const response = await callApi(Api.SaveSettings, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful
  };
};
