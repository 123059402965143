import common from './common';
import exceptions from './exceptions';
import home from './home';
import matcher from './matcher';
import mydocs from './mydocs';
import statement from './statement';

// eslint-disable-next-line import/no-default-export
export default {
  common,
  exceptions,
  home,
  matcher,
  mydocs,
  statement
};
