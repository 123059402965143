import { v4 } from 'uuid';
import _ from 'lodash';
import { E } from '../utils/errors';
import { ApiHelper } from './helper';
import { wait } from '../utils';

require('es6-promise').polyfill();

const initApi = {
  url: '',
  type: 'GET',
  fake: false
};

const initApiOptions = {
  credentials: 'include',
  cache: 'no-store',
  bffCall: true,
  uniqueRes: false,
  formData: false,
  body: undefined
};

export const callApi = async (Api = initApi, apiOption = initApiOptions) => {
  try {
    if (!Api || !Api.url) throw new Error('Invalid API');

    const apiConfig = {
      method: Api.type || initApi.type,
      bffCall: _.isNil(apiOption.bffCall) ? initApiOptions.bffCall : apiOption.bffCall,
      uniqueRes: apiOption.uniqueRes || initApiOptions.uniqueRes
    };

    const options = {
      headers: ApiHelper.generateHeaders(apiConfig.method, apiOption.formData),
      method: apiConfig.method,
      credentials: apiOption.credentials || initApiOptions.credentials,
      cache: apiOption.cache || initApiOptions.cache,
      body: apiOption.body || initApiOptions.body
    };

    const url = `${ApiHelper.getServerUrl(Api.fake, apiConfig.bffCall)}${Api.url}`;
    const res = await fetch(url, options).catch(error => {
      throw new Error(error);
    });

    const success = ApiHelper.checkStatus(url, res.status, options.method);
    if (!success) return null;

    const result = await ApiHelper.parseResponse(res);

    if (!result) throw new Error(E.UNEXPECTEDFETCH);
    if (result && apiConfig.uniqueRes) result.uniqueId = v4();

    return result;
  } catch (error) {
    if (_.includes(error.message, E.SESSIONEXPIRED)) await wait(1000);
    else console.error(error); // eslint-disable-line no-console
    throw new Error(error.message);
  }
};
