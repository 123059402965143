import './style.scss';

import React from 'react';
import PT from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import { StoresDictionary as ST } from '../../utils/constants';
import { Page, SectionContainer, BoxTitle } from '../../components/Layout';
import { ModalBox } from '../../components/Modal';
import { Modals } from '../../stores/SettingStore';
import { FormBox } from './FormBox';
import { Settings } from './Settings';

const InfoField = ({ label, value = '', className = '' }) => (
  <div className={`info-field ${className}`}>
    <span className="upcas link-silver-small">{label}</span>
    <p className="text-small orange-text no-margin">{value}</p>
  </div>
);

InfoField.propTypes = {
  label: PT.string.isRequired,
  value: PT.string,
  className: PT.string
};

const ContactForm = [
  { key: 'phone', type: 'text', label: 'Phone Number' },
  { key: 'email', type: 'email', label: 'Email' }
];

const ResetPasswordForm = [
  { key: 'currentPassword', type: 'password', label: 'Current password' },
  { key: 'newPassword', type: 'password', label: 'New password' },
  { key: 'confirmPassword', type: 'password', label: 'Confirm new password' }
];

export const MyProfile = inject(ST.SettingStore)(
  observer(({ SettingStore }) => {
    return (
      <Page boxed className="settings-marker">
        <Row>
          <Col sm={12}>
            <h2 className="setting-title margin-top-lg margin-bottom-sm">
              <i className="icon-user x-bigger dark-slate-blue margin-right-xs" />
              {`${SettingStore.userContacts.name} ${SettingStore.userContacts.surname}`}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="user-block greybox padding-md margin-bottom-xl">
              <InfoField label="username" value={SettingStore.userInfo.userName} />
              <InfoField className="margin-left-md" label="level" value={SettingStore.userContacts.role} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12}>
            <FormBox
              title="user contacts"
              fields={ContactForm}
              data={SettingStore.userContacts}
              submitLabel="update contacts"
              onSubmit={userContacts => SettingStore.updateUserContacts(userContacts)}
            />
            <FormBox
              title="user password"
              fields={ResetPasswordForm}
              submitLabel="change password"
              onSubmit={authInfo => SettingStore.changePassword(authInfo)}
            />
          </Col>
          <Col md={8} sm={12}>
            <SectionContainer>
              <BoxTitle title="settings" />
              <Settings
                confidenceCode={SettingStore.confidenceCode}
                loading={SettingStore.loading.settings}
                onChange={SettingStore.uploadConfCode}
                onUpdate={SettingStore.updateSettings}
              />
            </SectionContainer>
          </Col>
        </Row>
        <ModalBox open={SettingStore.modal === Modals.settingSuccess} title="Update Settings">
          <p className="alert-success padding-md margin-bottom-md">
            Your account settings has been successfully updated.
          </p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="corn-flower-blue-bg white" onClick={SettingStore.setModal}>
              Ok
            </button>
          </div>
        </ModalBox>
        <ModalBox open={SettingStore.modal === Modals.infoSuccess} title="User Information">
          <p className="alert-success padding-md margin-bottom-md">
            Your account informations has been successfully updated.
          </p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="corn-flower-blue-bg white" onClick={SettingStore.setModal}>
              Ok
            </button>
          </div>
        </ModalBox>
        <ModalBox open={SettingStore.modal === Modals.passwordSuccess} title="Change Password">
          <p className="alert-success padding-md margin-bottom-md">Your password has been successfully updated.</p>
          <div className="modal-buttons margin-top-lg">
            <button type="button" className="corn-flower-blue-bg white" onClick={SettingStore.setModal}>
              Ok
            </button>
          </div>
        </ModalBox>
        <ModalBox withError title="Unexpected Error" open={SettingStore.hasErrors} onClose={SettingStore.closeError}>
          <p className="steel big margin-bottom-lg">{SettingStore.error.message || 'There was a service error.'}</p>
        </ModalBox>
      </Page>
    );
  })
);
