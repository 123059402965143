export const Status = {
  NEW: 'NEW',
  SKIP: 'SKIP',
  TOMATCH: 'TOMATCH',
  WORKING: 'WORKING',
  MATCHED: 'MATCHED',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR'
};

export const RQTypes = {
  MBR: 0,
  AMT: 1,
  ACA: 2,
  CRA0: 3,
  CRA1: 4,
  0: 'Manual Browsing',
  1: 'Automatic Match',
  2: 'Automatic Credit Assessment',
  3: 'Credit Assessment',
  4: 'Credit Assessment'
};
