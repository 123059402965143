import './style.scss';

import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

export const IFormField = {
  key: PT.string,
  type: PT.string,
  label: PT.string
};

export const FileInput = ({ className, name, file, onChange, children }) => {
  const fileRef = React.useRef(null);
  const { t } = useTranslation('common');

  const onChangeFile = e => {
    const uploadedFile = e.target.files[0] ? e.target.files[0] : null;
    onChange(uploadedFile);
  };

  return (
    <div className={`upload-file ${className || ''}`}>
      <input ref={fileRef} style={{ display: 'none' }} type="file" name={name} onChange={onChangeFile} />
      <input readOnly type="text" className="gray-input no-margin" value={file ? file.name : ''} />
      <button
        type="button"
        className="button-big std-label upcas corn-flower-blue-bg margin-left-sm"
        onClick={() => fileRef.current.click()}
      >
        {t('Browse')}
      </button>
      {children}
    </div>
  );
};
FileInput.propTypes = {
  className: PT.string,
  name: PT.string,
  file: PT.shape({ name: PT.string }),
  onChange: PT.func,
  children: PT.node
};

export const DateRange = ({ name, value, placeholder, onChange }) => {
  return (
    <div className="daterange-container">
      <DateRangePicker
        showDropdowns
        alwaysShowCalendars
        onApply={(event, picker) => {
          const range = {
            start: moment(picker.startDate).startOf('day'),
            end: moment(picker.endDate).endOf('day')
          };
          onChange(range, event);
        }}
        locale={{
          applyLabel: 'confirm',
          cancelLabel: 'undo'
        }}
      >
        <input
          readOnly
          type="text"
          name={name}
          className="no-margin"
          value={
            value && value.start && value.end
              ? `${value.start.format ? value.start.format('L') : moment(value.start).format('L')} - ${
                  value.end.format ? value.end.format('L') : moment(value.end).format('L')
                }`
              : ''
          }
          placeholder={placeholder}
        />
      </DateRangePicker>
      <span role="presentation" className="close-container" onClick={e => onChange({ startDate: '', endDate: '' }, e)}>
        <i className="icon-close-small smaller" aria-hidden="true" />
      </span>
    </div>
  );
};
DateRange.propTypes = {
  name: PT.string,
  value: PT.oneOfType([PT.string, PT.shape({})]),
  placeholder: PT.string,
  onChange: PT.func
};
