import React from 'react';
import { observer } from 'mobx-react';
import { ViewsDictionary as VW } from './utils/constants';
import { Spinner } from './components/Spinner';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Page } from './components/Layout';
import { LogOn, ResetPassword, ChangePassword } from './containers/LogOn';
import { MyProfile } from './containers/MyProfile';
import { Home } from './containers/Home';
import { FileMatcher } from './containers/Matcher';
import { MyDocs } from './containers/MyDocs';
import { Statement } from './containers/Statement';

const ViewMapper = {
  [VW.LOGIN]: LogOn,
  [VW.RESETPASSWORD]: ResetPassword,
  [VW.CHANGEPASSWORD]: ChangePassword,
  [VW.MYPROFILE]: MyProfile,
  [VW.HOME]: Home,
  [VW.FILEMATCHER]: FileMatcher,
  [VW.MYDOCS]: MyDocs,
  [VW.STATEMENT]: Statement
};

export const App = observer(({ AppStore }) => {
  const renderSelectedPage = view => {
    if (!view) return null;
    const View = ViewMapper[view.name];
    return View ? (
      <View {...view.viewbag} />
    ) : (
      <Page boxed>
        <p className="alert-warning normal align-center padding-md">
          The view named <b>{view.name}</b> is missing or should be mapped. Add the reference to the dictionary.
        </p>
      </Page>
    );
  };

  const renderCurrentView = view => {
    if (!view) return null;
    const status = view.data && view.data.state;
    switch (status) {
      case 'pending':
        return <Spinner size={4} loading className="page-loading-spinner padding-xl" />;
      case 'rejected':
        return <div>rejected</div>;
      case 'fulfilled':
        return renderSelectedPage(view);
      default:
        throw new Error('unable to resolve render view promise');
    }
  };

  const enabledHeader = () => {
    const view = AppStore.currentView;
    return view && view.name !== VW.LOGIN && view.name !== VW.RESETPASSWORD && view.name !== VW.CHANGEPASSWORD;
  };

  return !AppStore.loadingProfile ? (
    <>
      {enabledHeader() && <Header userProfile={AppStore.userProfile} />}
      <div className="main-content">{renderCurrentView(AppStore.currentView)}</div>
      <Footer />
    </>
  ) : (
    <Spinner size={4} loading className="page-loading-spinner padding-xl" />
  );
});
