import { Router } from 'director/build/director';
import { RoutesDictionary as RT } from '../utils/constants';
import { routesMap } from './routes';

export class AppRouter {
  constructor(AppStore) {
    this.authGuard = AppStore.authGuard;
    this.loadUserProfile = AppStore.getUserProfile;
    this.router = new Router(routesMap(AppStore.showView, this.routeGuard));
  }

  startRouter = async () => {
    this.router.configure({
      notfound: () => {
        this.router.setRoute(RT.HOME);
      },
      html5history: false,
      async: true
    });
    await this.loadUserProfile();
    this.router.init(RT.HOME);
  };

  routeGuard = async (route, optionalConditions = true, fallbackRoute = RT.HOME) => {
    const isLoggedIn = await this.authGuard();
    if (!isLoggedIn) return this.router.setRoute(RT.LOGIN);
    if (!optionalConditions) return this.router.setRoute(fallbackRoute);
    return route();
  };
}
