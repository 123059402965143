import React from 'react';
import PT from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { BusyElement } from '../../components/Spinner';
import { ICandidate } from './common/types';

export const CandidatesList = withTranslation('matcher')(({ t, candidates, onSelect, loading, disableSelect, detailList, getFamilyTree }) => (
  <BusyElement loading={loading}>
    <div className="candidates-list align-left">
      {candidates.length > 0 ? (
        _.map(candidates, (candidate, idx) => (
          <div key={idx} className="shadowed-box">
            <div className="candidate-container">
              <div className="confidence">
                <div className="smaller bold upcas dark-slate-blue margin-bottom-xs">
                  {t('CandidateFields.ConfidenceCode')}
                </div>
                <div className="code dusty-orange">{candidate.confidenceCode}</div>
              </div>
              <div className="info-container padding-y-md">
                <h5 className="normal dark-slate-blue align-left">
                  <i className="icon-azienda margin-right-sm" aria-hidden="true" />
                  {candidate.name}
                </h5>
                <div className="info">
                  <div className="info-box">
                    <div className="smaller silver">{t('CandidateFields.LegalStatus')}</div>
                    <div className="dark-slate-blue">{candidate.legalStatus || '-'}</div>
                  </div>
                  <div className="info-box">
                    <div className="smaller silver">{t('CandidateFields.Address')}</div>
                    <div className="dark-slate-blue">{candidate.address || '-'}</div>
                  </div>
                  <div className="info-box">
                    <div className="smaller silver">{t('CandidateFields.Town')}</div>
                    <div className="dark-slate-blue">{candidate.town || '-'}</div>
                  </div>
                  <div className="info-box">
                    <div className="smaller silver">{t('CandidateFields.ZipCode')}</div>
                    <div className="dark-slate-blue">{candidate.cap || '-'}</div>
                  </div>
                  <div className="info-box">
                    <div className="smaller silver">{t('CandidateFields.Site')}</div>
                    <div className="dark-slate-blue">{candidate.legalStatus || '-'}</div>
                  </div>
                  {detailList.find(d => d.duns === candidate.dunsNbr) && <div className="info-box">
                    <div className="smaller silver">{t('CandidateFields.Sic')}</div>
                    <div className="dark-slate-blue">{detailList.find(d => d.duns === candidate.dunsNbr).sic || '-'}</div>
                  </div>}
                </div>
              </div>
              <div className="action padding-x-lg">
                <button
                  type="button"
                  className="button-big std-label upcas corn-flower-blue-bg"
                  onClick={() => onSelect(candidate)}
                  disabled={disableSelect || loading}
                >
                  {t('Select')}
                </button>
              </div>
            </div>
            <FamilyTree
              t={t}
              detailList={detailList}
              candidate={candidate}
              getFamilyTree={getFamilyTree}
            />
          </div>
        ))
      ) : (
          <div className="no-match padding-md">
            <p className="steel1 italic align-center no-margin">{t('NoCandidates')}</p>
          </div>
        )}
    </div>
  </BusyElement>
));

const FamilyTree = ({ t, detailList, candidate, getFamilyTree }) => {
  const detail = detailList.find(d => d.duns === candidate.dunsNbr);
  if (detail) {//(detailList.duns === candidate.dunsNbr)
    if (detail.errorMessage === undefined)
      return (
        <div className=" margin-bottom-lg padding-y-md">
          <div className="candidate-container">
            <a href="/" className="bold dark-slate-blue margin-left-lg link-with-icon"
              onClick={e => {
                e.preventDefault();
                //onRework(1, request.id, request.status, request.type);
              }}
            ><i className="icon-arrow_down" aria-hidden="true" style={{ backgroundColor: '#ef7423' }} />Family Tree</a>
          </div>
          <div className="candidate-container">
            <div className="confidence">
              <div className="code dusty-orange">HQ</div>
            </div>
            <div className="info-container padding-y-md">
              <h5 className="normal dark-slate-blue align-left">
                <i className="icon-azienda margin-right-sm" aria-hidden="true" />
                {detail.hqName || '-'}
              </h5>
              <div className="info">
                <div className="info-box" style={{ marginRight: '5%' }}>
                  <div className="smaller silver">{t('CandidateFields.Address')}</div>
                  <div className="dark-slate-blue">{detail.hqAddress || '-'}</div>
                </div>
                <div className="info-box">
                  <div className="smaller silver">{t('CandidateFields.Town')}</div>
                  <div className="dark-slate-blue">{detail.hqTown || '-'}</div>
                </div>
                <div className="info-box">
                  <div className="smaller silver">{t('CandidateFields.Country')}</div>
                  <div className="dark-slate-blue">{detail.hqCountry || '-'}</div>
                </div>
              </div>
            </div>

            <div className="confidence">
              <div className="code dusty-orange">GU</div>
            </div>
            <div className="info-container padding-y-md">
              <h5 className="normal dark-slate-blue align-left">
                <i className="icon-azienda margin-right-sm" aria-hidden="true" />
                {detail.guName || '-'}
              </h5>
              <div className="info">
                <div className="info-box" style={{ marginRight: '5%' }}>
                  <div className="smaller silver">{t('CandidateFields.Address')}</div>
                  <div className="dark-slate-blue">{detail.guAddress || '-'}</div>
                </div>
                <div className="info-box">
                  <div className="smaller silver">{t('CandidateFields.Town')}</div>
                  <div className="dark-slate-blue">{detail.guTown || '-'}</div>
                </div>
                <div className="info-box">
                  <div className="smaller silver">{t('CandidateFields.Country')}</div>
                  <div className="dark-slate-blue">{detail.guCountry || '-'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className=" margin-bottom-lg padding-y-md">
          <div className="candidate-container">
            <a href="/" className="bold dark-slate-blue margin-left-lg link-with-icon"
              onClick={e => {
                e.preventDefault();
                //onRework(1, request.id, request.status, request.type);
              }}
            ><i className="icon-arrow_down" aria-hidden="true" style={{ backgroundColor: '#ef7423' }} />Family Tree</a>
          </div>
          <div className="candidate-container">
            <div className="dark-slate-blue margin-left-lg">
              {detail.errorMessage || '-'}
            </div>
          </div>
        </div>
      );
  }
  else
    return (<div className=" margin-bottom-lg padding-y-md">
      <div className="candidate-container">
        <a href="/" className="bold dark-slate-blue margin-left-lg link-with-icon"
          onClick={e => {
            e.preventDefault();
            getFamilyTree(candidate.dunsNbr);
          }}
        ><i className="icon-arrow_right" aria-hidden="true" style={{ backgroundColor: '#ef7423' }} />Family Tree</a>
      </div>
    </div>
    );
}

CandidatesList.propTypes = {
  candidates: PT.arrayOf(PT.shape(ICandidate)),
  onSelect: PT.func,
  loading: PT.bool,
  disableSelect: PT.bool
};
