module.exports = {
  GetMyDocsMailBoxes: {
    url: '/Cacib/GetMyDocsMailBoxes',
    type: 'POST',
    fake: false
  },
  GetMailBoxInputFile: {
    url: '/Cacib/GetMailBoxInputFile',
    type: 'POST',
    fake: false
  },
  GetMailBoxOutputFile: {
    url: '/Cacib/GetMailBoxOutputFile',
    type: 'POST',
    fake: false
  },
  ManualBrowsing: {
    url: '/Cacib/ManualBrowsing',
    type: 'POST',
    fake: false
  },
  RequireCreditAssessment: {
    url: '/Cacib/RequireCreditAssessment',
    type: 'POST',
    fake: false
  },
  RestartProcessingMatchDocument: {
    url: '/Cacib/RestartProcessingMatchDocument',
    type: 'POST',
    fake: false
  },
  GetUsage: {
    url: '/Cacib/GetUsage',
    type: 'POST',
    fake: false
  },
  GetUsageResultFile: {
    url: '/Cacib/GetUsageResultFile',
    type: 'POST',
    fake: false
  }
};
