export const ViewsDictionary = {
  LOGIN: 'LOGIN',
  RESETPASSWORD: 'RESETPASSWORD',
  CHANGEPASSWORD: 'CHANGEPASSWORD',
  MYPROFILE: 'MYPROFILE',
  HOME: 'HOME',
  FILEMATCHER: 'FILEMATCHER',
  MYDOCS: 'MYDOCS',
  STATEMENT: 'STATEMENT'
};

export const RoutesDictionary = {
  LOGIN: 'Account/LogOn',
  RESETPASSWORD: 'Account/ResetPassword',
  CHANGEPASSWORD: 'Account/ChangePassword',
  MYPROFILE: 'Account/MyProfile',
  HOME: 'Home/Index',
  MYDOCS: 'Storage/MyDocs',
  FILEMATCHER: id => `FileMatch/${id}`,
  STATEMENT: 'Storage/Statement'
};

export const StoresDictionary = {
  UserStore: 'UserStore',
  SettingStore: 'SettingStore',
  HomeStore: 'HomeStore',
  MyDocsStore: 'MyDocsStore',
  MatchStore: 'MatchStore',
  StatementStore: 'StatementStore'
};

export const ViewStoreConnect = {
  [ViewsDictionary.LOGIN]: StoresDictionary.UserStore,
  [ViewsDictionary.MYPROFILE]: StoresDictionary.SettingStore,
  [ViewsDictionary.HOME]: StoresDictionary.HomeStore,
  [ViewsDictionary.FILEMATCHER]: StoresDictionary.MatchStore,
  [ViewsDictionary.MYDOCS]: StoresDictionary.MyDocsStore,
  [ViewsDictionary.STATEMENT]: StoresDictionary.StatementStore
};

export const StorageDictionary = {
  UserInfo: 'UsrNfo'
};
