// eslint-disable-next-line import/no-default-export
export default {
  PageTitle: 'Request Statement',
  Total: 'total requests',
  EmptyLabel: 'no requests found for selected period',
  DownloadExcel: 'Download Excel',
  Filters: {
    DateRange: 'request date from - to',
    DateFrom: 'date from',
    DateTo: 'date to'
  },
  Fields: {
    Type: 'type',
    Duns: 'DUNS',
    Country: 'country',
    RequestDate: 'requested on',
    Price: 'price (€)'
  }
};
