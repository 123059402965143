import React from 'react';
import PT from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import { IRequest } from './common/types';

export const SubjectDetails = withTranslation('matcher')(({ t, subject }) => (
  <div className="greybox-dark padding-md align-left margin-bottom-xl">
    <Row>
      <Col lg={7} sm={12} className="subject-container">
        <div className="info-container padding-y-md">
          <h5 className="normal dark-slate-blue align-left">
            <i className="icon-azienda margin-right-sm" aria-hidden="true" />
            {subject.name}
          </h5>
          <div className="info">
            <div className="info-box">
              <div className="smaller silver">{t('SubjectFields.Address')}</div>
              <div className="dark-slate-blue">{subject.address || '-'}</div>
            </div>
            <div className="info-box">
              <div className="smaller silver">{t('SubjectFields.Town')}</div>
              <div className="dark-slate-blue">{subject.city || '-'}</div>
            </div>
            <div className="info-box">
              <div className="smaller silver">{t('SubjectFields.ZipCode')}</div>
              <div className="dark-slate-blue">{subject.postCode || '-'}</div>
            </div>
          </div>
        </div>
      </Col>
      <Col lg={5} sm={12}>
        <Row className="margin-bottom-sm">
          <Col md={6} sm={12}>
            <div className="upcas link-silver-small">{t('SubjectFields.Duns')}</div>
            <div className="dark-slate-blue">{subject.duns || '-'}</div>
          </Col>
          <Col md={6} sm={12}>
            <div className="upcas link-silver-small">{t('SubjectFields.BusinessId')}</div>
            <div className="dark-slate-blue">{subject.businessId || '-'}</div>
          </Col>
        </Row>
        <Row className="margin-bottom-sm">
          <Col md={6} sm={12}>
            <div className="upcas link-silver-small">{t('SubjectFields.DebtorCode')}</div>
            <div className="dark-slate-blue">{subject.debtorCode || '-'}</div>
          </Col>
          <Col md={6} sm={12}>
            <div className="upcas link-silver-small">{t('SubjectFields.CountryCode')}</div>
            <div className="dark-slate-blue">{subject.countryCode || '-'}</div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <div className="upcas link-silver-small">{t('SubjectFields.GURating')}</div>
            <div className="dark-slate-blue">{subject.guRatingNeeded || '-'}</div>
          </Col>
          <Col md={6} sm={12}>
            <div className="upcas link-silver-small">{t('SubjectFields.Country')}</div>
            <div className="dark-slate-blue">{subject.county || '-'}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
));

SubjectDetails.propTypes = {
  subject: PT.shape(IRequest).isRequired
};
