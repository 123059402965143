import _ from 'lodash';
import moment from 'moment';
import Api from './api';
import { callApi } from '../fetch';

export const GetDocuments = async inputFilters => {
  const response = await callApi(Api.GetMyDocsMailBoxes, {
    body: JSON.stringify(inputFilters)
  }).catch(error => {
    throw new Error(error);
  });

  const shift = arr => {
    const res = arr[0] > 0 ? arr : _.drop(arr);
    return !res.length || res[0] > 0 ? res : shift(res);
  };

  const getLeftTime = timeString => {
    let result = timeString;
    let days = 0;
    if (_.includes(result, '.')) {
      const idx = result.indexOf('.');
      days = _.toNumber(result.substring(0, idx));
      result = result.substring(idx + 1);
    }
    result = _.split(result, ':');
    result = shift(_.map(result, _.toNumber));
    if (result.length > 1) {
      result = _.dropRight(result);
      result = result.length > 1 ? `${result[0]}h ${result[1] > 0 ? `${result[1]}min` : ''}` : `${result[0]}min`;
    } else {
      result = days > 0 ? '' : 'less than minute';
    }
    return _.trim(`${days > 0 ? `${days}d` : ''} ${result}`);
  };

  return {
    ok: response.isSuccessful,
    requestsList: _.map(response.mailBoxes, item => ({
      id: item.guid,
      userName: item.codUser,
      requestName: item.requestName,
      fileName: item.filename,
      deliveryTime: getLeftTime(item.estimatedDeliveryTime),
      type: item.processing,
      typeLabel: item.processingString,
      status: item.state,
      insertDate: moment(item.insertDate).format('L LT'),
      matchAmount: item.numMatched,
      noMatchAmount: item.numNotMatched,
      processedAmount: item.processedRecords,
      totalAmount: item.numInput,
      errorAmount: item.numErr,
      confidenceCode: item.confidenceCode,
      excludeOOB: item.excludeOOB,
      excludeBranches: item.excludeBranches
    })),
    drillDowns: response.drillDownFilters,
    pagingInfo: response.pagingInfo
  };
};

export const GetInputFile = async inputData => {
  const response = await callApi(Api.GetMailBoxInputFile, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return response;
};

export const GetOutputFile = async inputData => {
  const response = await callApi(Api.GetMailBoxOutputFile, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return response;
};

export const RequireManualBrowsing = async inputData => {
  const response = await callApi(Api.ManualBrowsing, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful
  };
};

export const RequireCreditAssessment = async inputData => {
  const response = await callApi(Api.RequireCreditAssessment, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful
  };
};

export const RestartProcessingMatchDocument = async inputData => {
  const response = await callApi(Api.RestartProcessingMatchDocument, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful
  };
};

export const GetUsage = async inputData => {
  const response = await callApi(Api.GetUsage, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful,
    requestsList: _.map(response.items, item => ({
      ...item,
      requestDate: moment(item.requestDate).format('L'),
      price: _.isNumber(item.price) ? item.price.toFixed(2) : '-'
    })),
    pagingInfo: response.pagingInfo
  };
};

export const GetUsageResultFile = async inputData => {
  const response = await callApi(Api.GetUsageResultFile, {
    body: JSON.stringify(inputData)
  }).catch(error => {
    throw new Error(error);
  });
  return response;
};
