import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'bootstrap/scss/bootstrap.scss';
import 'font-awesome/css/font-awesome.min.css';
import './styles/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { AppStore } from './stores/AppStore';
import { AppRouter } from './router';
import { App } from './App';

const RootStore = new AppStore();
const Router = new AppRouter(RootStore);
Router.startRouter();

ReactDOM.render(
  <Provider {...RootStore.stores}>
    <App AppStore={RootStore} />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
