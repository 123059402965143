import { decorate, observable, action, runInAction, computed } from 'mobx';
import _ from 'lodash';
import * as API from '../api/login';
import { RoutesDictionary as RT, StorageDictionary as STG } from '../utils/constants';
import { Storage } from '../utils/storage';
import { wait } from '../utils';
import { ApiHelper } from '../api/helper';

const T = {
  auth: 'auth',
  reset: 'reset',
  change: 'change'
};

export const Modals = {
  close: 'close',
  resetSuccess: 'resetSuccess'
};

export class UserStore {
  error = {
    auth: false,
    reset: false,
    change: false
  };

  loading = {
    auth: false,
    reset: false,
    change: false
  };

  userInfo = null;

  temporaryPassword = null;

  sessionBackUrl = null;

  userValidation = true;

  passwordValidation = true;

  authenticationFailed = false;

  resetPasswordFailed = false;

  passwordFailed = false;

  loadingAuth = false;

  modal = Modals.close;

  constructor(getUserProfile) {
    this.getUserProfile = getUserProfile;
  }

  load = async () => {
    await this.getUserInfo();
    this.sessionBackUrl = _.includes(window.location.hash, 'BackUrl')
      ? decodeURIComponent(window.location.hash.substring(window.location.hash.indexOf('=') + 1))
      : null;
  };

  getUserInfo = async () => {
    const userInfo = await Storage.read(STG.UserInfo);
    runInAction(() => {
      this.userInfo = userInfo || null;
    });
  };

  setError = (type, status) => {
    this.error[type] = status;
  };

  setLoading = (type, status) => {
    this.loading[type] = status;
  };

  setModal = type => {
    if (!type) this.modal = Modals.close;
    else this.modal = type;
  };

  validateUserForm = inputForm => {
    this.userValidation = _.trim(inputForm.user) !== '' && _.trim(inputForm.password) !== '';
  };

  validateChangePassword = inputForm => {
    this.passwordValidation =
      _.trim(inputForm.newPassword) !== '' &&
      _.trim(inputForm.confirmPassword) !== '' &&
      inputForm.newPassword === inputForm.confirmPassword;
  };

  goToLogin = () => {
    window.location.href = `#${RT.LOGIN}`;
    this.setModal(Modals.close);
    this.resetPasswordFailed = false;
  };

  goToChangePassword = temporaryPassword => {
    this.temporaryPassword = temporaryPassword;
    window.location.href = `#${RT.CHANGEPASSWORD}`;
  };

  proceedAuth = () => {
    this.getUserProfile();
    if (this.sessionBackUrl) {
      window.location.href = this.sessionBackUrl;
      this.sessionBackUrl = null;
    } else window.location.href = `#${RT.HOME}`;
  };

  userAuthentication = async inputForm => {
    this.validateUserForm(inputForm);
    if (!this.userValidation) return;
    this.setLoading(T.auth, true);
    try {
      const result = await API.Login({
        userName: inputForm.user,
        password: inputForm.password
      });
      await Storage.store(STG.UserInfo, result);
      runInAction(() => {
        this.userInfo = result;
      });
      if (result.expiredPsw) {
        this.goToChangePassword(inputForm.password);
      } else {
        runInAction(() => {
          this.authenticationFailed = !this.authenticated;
        });
        if (this.authenticated) this.proceedAuth();
      }
    } catch (error) {
      this.setError(T.auth, true);
    }
    this.setLoading(T.auth, false);
  };

  changePassword = async inputForm => {
    this.validateChangePassword(inputForm);
    if (!this.passwordValidation) {
      runInAction(() => {
        this.passwordFailed = true;
      });
      return;
    }
    this.setLoading(T.change, true);
    try {
      runInAction(() => {
        this.passwordFailed = false;
      });
      const newAuth = {
        user: this.userInfo.userName,
        password: inputForm.newPassword
      };
      const result = await API.ChangePassword({
        userName: newAuth.user,
        oldPassword: this.temporaryPassword,
        newPassword: newAuth.password
      });
      if (!result.ok) throw new Error();
      await wait(2000);
      await this.userAuthentication(newAuth);
    } catch {
      this.setError(T.change, true);
    }
    this.setLoading(T.change, false);
  };

  resetPassword = async inputForm => {
    this.setLoading(T.reset, true);
    try {
      const result = await API.ResetPassword({
        userCode: inputForm.user,
        emailTo: inputForm.email
      });
      runInAction(() => {
        this.resetPasswordFailed = result.resultCode !== 0;
      });
      if (!this.resetPasswordFailed) this.setModal(Modals.resetSuccess);
    } catch (error) {
      this.setError(T.reset, true);
    }
    this.setLoading(T.reset, false);
  };

  userLogout = async () => {
    await ApiHelper.userLogout();
  };

  get authenticated() {
    return this.userInfo && this.userInfo.authenticated;
  }

  get userName() {
    return this.userInfo && this.userInfo.userName;
  }

  get userFullname() {
    return this.userInfo && this.userInfo.userFullname;
  }
}

decorate(UserStore, {
  error: observable,
  loading: observable,
  userInfo: observable,
  userValidation: observable,
  passwordValidation: observable,
  authenticationFailed: observable,
  resetPasswordFailed: observable,
  passwordFailed: observable,
  modal: observable,
  setError: action,
  setLoading: action,
  setModal: action,
  validateUserForm: action,
  validateChangePassword: action,
  goToLogin: action,
  goToChangePassword: action,
  authenticated: computed,
  userName: computed
});
