import React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import { StyledTable as Table, ITableField } from '../../components/Table';

export const RequestsList = ({ t, loading, fields, data = [], empty }) => (
  <Table>
    <Table.Header fields={fields} t={key => t(`Fields.${key}`)} />
    <Table.Body>
      {data.length > 0 || loading ? (
        _.map(data, (item, idx) => (
          <Table.Row key={idx} fields={fields} value={key => item[key]} highlight={key => key === 'fileName'} />
        ))
      ) : (
        <Table.EmptyRow colSpan={fields.length + 1} label={empty} />
      )}
    </Table.Body>
  </Table>
);

RequestsList.propTypes = {
  t: PT.func,
  loading: PT.bool,
  fields: PT.arrayOf(PT.shape(ITableField)).isRequired,
  data: PT.arrayOf(PT.shape({})),
  empty: PT.string
};
