import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap';

export const PageSize = ({ onChange, selected = 10, sizes = [10, 20, 50] }) => {
  const { t } = useTranslation('common');

  const changePageSize = size => onChange(Number(size));

  return (
    <div className="page-size margin-left-md">
      <Dropdown onSelect={size => changePageSize(size)}>
        <Dropdown.Toggle className="size-changer">{selected}</Dropdown.Toggle>
        <Dropdown.Menu>
          {_.map(sizes, (size, idx) => (
            <Dropdown.Item key={idx} eventKey={size}>
              {size}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
        <span className="big margin-left-sm">{t('PerPage')}</span>
      </Dropdown>
    </div>
  );
};

PageSize.propTypes = {
  onChange: PT.func.isRequired,
  selected: PT.number,
  sizes: PT.arrayOf(PT.number)
};
