import _ from 'lodash';
import moment from 'moment';
import Api from './api';
import { callApi } from '../fetch';

export const GetMatchingList = async inputFilters => {
  const response = await callApi(Api.GetHomeMailBoxes, {
    body: JSON.stringify(inputFilters)
  }).catch(error => {
    throw new Error(error);
  });
  return {
    ok: response.isSuccessful,
    matchList: _.map(response.mailBoxes, item => ({
      id: item.guid,
      fileName: item.filename,
      status: item.state,
      insertDate: moment(item.insertDate).format('L LT'),
      matchAmount: item.numMatched,
      noMatchAmount: item.numNotMatched
    })),
    pagingInfo: response.pagingInfo
  };
};

export const SubmitMatchingDocument = async inputData => {
  const response = await callApi(Api.UploadMailBoxInputFile, {
    body: inputData,
    formData: true
  }).catch(error => {
    throw new Error(error);
  });
  return response;
};

export const GetSettings = async () => {
  const response = await callApi(Api.GetSettings).catch(error => {
    throw new Error(error);
  });
  return response.settings;
};