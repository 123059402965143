import { decorate, observable, action, runInAction } from 'mobx';
import * as API from '../api/home';
import { RoutesDictionary as RT } from '../utils/constants';

const T = {
  submit: 'submit',
  list: 'list'
};

const defaultSetting = { exludeOutOfBusiness: true, excludeBranches: true, confidenceCode: 9 };

export const Modals = {
  close: 'none',
  success: 'success',
  error: 'error'
};

export const MatchTypes = {
  MBR: 0,
  AMT: 1,
  ACA: 2
};

export class HomeStore {
  loading = {
    submit: false,
    list: false
  };

  error = {
    message: '',
    submit: false,
    list: false
  };

  modal = Modals.close;

  pagingInfo = {
    pageIndex: 0,
    pageSize: 10,
    pagesNumber: 0,
    recordsTotalNumber: 0
  };

  matchFileList = [];

  settings = defaultSetting;

  load = async ({ userProfile }) => {
    this.closeError();
    runInAction(() => {
      this.matchFileList = [];
      this.pagingInfo = {
        pageIndex: 0,
        pageSize: 10,
        pagesNumber: 0,
        recordsTotalNumber: 0
      };
    });
    await this.getMatchList();
    this.settings = await API.GetSettings();
  };

  setLoading = (type, status) => {
    this.loading[type] = status;
  };

  setError = (type, status, message) => {
    if (message) this.error.message = message;
    this.error[type] = status;
  };

  closeError = () => {
    this.error = {
      ...this.error,
      submit: false,
      list: false
    };
  };

  setModal = type => {
    if (!type) this.modal = Modals.close;
    else this.modal = type;
  };

  getMatchList = async () => {
    this.setLoading(T.list, true);
    try {
      const result = await API.GetMatchingList({
        pagingInfo: {
          pageIndex: this.pagingInfo.pageIndex,
          pageSize: this.pagingInfo.pageSize
        }
      });
      if (!result) throw new Error();
      runInAction(() => {
        this.matchFileList = result.matchList;
        this.pagingInfo = { ...this.pagingInfo, ...result.pagingInfo };
      });
    } catch {
      this.setError(T.list, true);
    }
    this.setLoading(T.list, false);
  };

  changePage = page => {
    this.pagingInfo.pageIndex = page;
    this.getMatchList();
  };

  changePageSize = size => {
    this.pagingInfo = {
      ...this.pagingInfo,
      pageIndex: 0,
      pageSize: size
    };
    this.getMatchList();
  };

  submitFile = async inputData => {
    this.setLoading(T.submit, true);
    let success = false;
    try {
      const fileData = new FormData();
      fileData.append('file', inputData.file);
      fileData.append('uploadType', inputData.uploadType);
      fileData.append('excludeOutOfBusiness', inputData.exludeOutOfBusiness);
      fileData.append('excludeBranches', inputData.excludeBranches);
      fileData.append('confidenceCode', inputData.confidenceCode);
      const result = await API.SubmitMatchingDocument(fileData).catch(() => {
        this.setError(T.submit, true);
      });
      success = result && result.isSuccessful;
      if (!result) throw new Error();
      if (!result.isSuccessful) {
        this.setError('message', result.errorMessage);
        this.setModal(Modals.error);
      } else this.setModal(Modals.success);
    } catch {
      this.setError(T.submit, true, 'There was an error on upload file service');
    }
    this.setLoading(T.submit, false);
    return success;
  };

  goToMyDocs = () => {
    this.setModal(Modals.close);
    window.location.href = `#${RT.MYDOCS}`;
  };

  openMatching = requestId => {
    window.location.href = `#${RT.FILEMATCHER(requestId)}`;
  };
}

decorate(HomeStore, {
  loading: observable,
  error: observable,
  modal: observable,
  pagingInfo: observable,
  matchFileList: observable,
  settings: observable,
  updateMatchList: action,
  setLoading: action,
  setError: action,
  closeError: action,
  setModal: action,
  changePage: action,
  changePageSize: action,
  submitFile: action
});
