import { decorate, observable, action, runInAction, computed } from 'mobx';
import moment from 'moment';
import * as API from '../api/mydocs';
import { downloadData } from '../utils';

const LoaderTypes = {
  List: 'list',
  Download: 'download'
};

export class StatementStore {
  loading = {
    list: false,
    download: false
  };

  error = {
    message: '',
    list: false,
    download: false
  };

  userInfo = null;

  pagingInfo = {
    pageIndex: 0,
    pageSize: 10,
    pagesNumber: 0,
    recordsTotalNumber: 0
  };

  defaultFilters = {
    requestDate: {
      start: moment()
        .subtract(1, 'months')
        .startOf('day')
        .toISOString(),
      end: moment().toISOString()
    }
  };

  requestFilters = { ...this.defaultFilters };

  requestsList = [];

  load = async ({ userProfile }) => {
    this.initStore();
    this.userInfo = userProfile;
    this.getRequests();
  };

  initStore = () => {
    this.closeError();
    this.requestsList = [];
    this.pagingInfo = {
      pageIndex: 0,
      pageSize: 10,
      pagesNumber: 0,
      recordsTotalNumber: 0
    };
  };

  setLoading = (type, status) => {
    this.loading[type] = status;
  };

  setError = (type, status, message) => {
    if (message) this.error.message = message;
    this.error[type] = status;
  };

  closeError = () => {
    this.error = {
      ...this.error,
      list: false,
      download: false
    };
  };

  changePage = page => {
    this.pagingInfo.pageIndex = page;
    this.getRequests();
  };

  changePageSize = size => {
    this.pagingInfo = {
      ...this.pagingInfo,
      pageIndex: 0,
      pageSize: size
    };
    this.getRequests();
  };

  updateFilters = inputFilters => {
    this.requestFilters = { ...this.requestFilters, ...inputFilters };
    this.pagingInfo.pageIndex = 0;
    this.getRequests();
  };

  resetFilters = () => {
    this.requestFilters = { ...this.defaultFilters };
    this.pagingInfo.pageIndex = 0;
    this.getRequests();
  };

  getQueryFilters = () => {
    return {
      dateFrom: this.requestFilters.requestDate.start,
      dateTo: this.requestFilters.requestDate.end
    };
  };

  getRequests = async () => {
    this.setLoading(LoaderTypes.List, true);
    try {
      const updatedFilters = this.getQueryFilters();
      const result = await API.GetUsage({
        ...updatedFilters,
        pagingInfo: {
          pageIndex: this.pagingInfo.pageIndex,
          pageSize: this.pagingInfo.pageSize
        }
      });
      runInAction(() => {
        this.requestsList = result.requestsList;
        this.pagingInfo = { ...this.pagingInfo, ...result.pagingInfo };
      });
    } catch (error) {
      this.setError(LoaderTypes.List, true);
    }
    this.setLoading(LoaderTypes.List, false);
  };

  downloadExcel = async () => {
    this.setLoading(LoaderTypes.Download, true);
    try {
      const updatedFilters = this.getQueryFilters();
      const result = await API.GetUsageResultFile(updatedFilters);
      if (!result) throw new Error();
      const name = `Requests_Statement_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
      downloadData(result.fileData, 'application/vnd.ms-excel', name);
    } catch {
      this.setError(LoaderTypes.Download, true, 'There was an error on download excel service');
    }
    this.setLoading(LoaderTypes.Download, false);
  };

  get downloadExcelEnabled() {
    return this.requestsList.length > 0;
  }
}

decorate(StatementStore, {
  loading: observable,
  error: observable,
  pagingInfo: observable,
  requestsList: observable,
  setLoading: action,
  setError: action,
  closeError: action,
  updateFilters: action,
  resetFilters: action,
  changePage: action,
  changePageSize: action,
  downloadExcelEnabled: computed
});
